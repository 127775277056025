@import "src/scss/variables";
@import "../../../scss/mixins/breakpoint";

.articleSlider {
  margin: 80px 0 80px 0;
  //opacity: 0;
  position: relative;

  @include desktop-1600 {
    height: 580px;
  }
  @include desktop-xl {
    height: 580px;
  }
  @include desktop1440 {
    height: 500px;
  }
  @include desktop {
    height: 501px;
  }
  @include laptop {
    height: 399px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  @include mobileL {
    height: 180px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .swiperWrapper{
    position: absolute;
    .articleSliderImg {
      img {
        margin-bottom: 10px;
        border-radius: 8px;
        object-fit: cover;
        width: 100%;

        @include desktop-1600 {
          height: 580px;
        }
        @include desktop-xl {
          height: 580px;
        }
        @include desktop1440 {
          height: 500px;
        }
        @include desktop {
          height: 501px;
        }
        @include laptop {
          height: 399px;
        }
        @include mobileL {
          height: 180px;
        }
      }

      figcaption {
        font-weight: 400;
        font-style: italic;
        font-size: 20px;
        line-height: 160%;
        text-align: left;
        opacity: 0.6;

        @include devices {
          font-size: 16px;
        }

        @include laptop-2 {
          font-size: 18px;
        }
      }
    }
  }

  :global(.swiper) {
    overflow: visible;
  }

  :global(.swiper-slide) {
    opacity: 0.7;
  }

  :global(.swiper-slide-active) {
    opacity: 1;
  }

  @include laptop-2 {
    margin-left: 35px;
    margin-right: 35px;
  }

  @include desktop1600 {
    margin-right: auto;
    margin-left: auto;
    max-width: 1384px;
  }

  @include desktop-xxl {
    position: relative;

    //&::after {
    //  content: "";
    //  position: absolute;
    //  right: calc(-50vw + 380px);
    //  top: 0;
    //  z-index: 5;
    //  height: 100%;
    //  width: 473px;
    //  background: linear-gradient(269.05deg, #F7F7F5 40.84%, rgba(255, 255, 255, 0) 99.21%);
    //}
  }
}
