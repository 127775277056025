@import "src/scss/variables";
@import "../../../scss/mixins/breakpoint";

.projectsList {
  position: relative;
  width: 100%;
}

.projectsMap {
  position: relative;
  width: 100%;
  margin-top: 24px;
  aspect-ratio: 16/9;

  // cursor: url(../images/new-builds/cursor.svg) 27 29, auto !important;
}

.buttonZoomIn {
  position: absolute;
  right: 30px;
  bottom: 94px;
  border: 0;
  width: 64px;
  height: 64px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  transition: $duration-normal;

  svg {
    width: 30px;
    height: 30px;

    @include laptop {
      fill: $color-tangelo;
    }

    @include desktop1024 {
      opacity: 0.5;
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 6px;
    right: 6px;
    bottom: 0;
    height: 2px;
    background-color: #EBEBEB;
  }

  &:hover {
    background-color: $color-white-smoke;
  }

  @include laptop {
    right: 15px;
    bottom: 47px;
    width: 32px;
    height: 32px;
  }
}

.buttonZoomOut {
  position: absolute;
  right: 30px;
  bottom: 30px;
  border: 0;
  width: 64px;
  height: 64px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  transition: $duration-normal;

  svg {
    width: 30px;
    height: 30px;

    @include laptop {
      fill: $color-tangelo;
    }

    @include desktop1024 {
      opacity: 0.5;
    }
  }

  &:hover {
    background-color: $color-white-smoke;
  }

  @include laptop {
    right: 15px;
    bottom: 15px;
    width: 32px;
    height: 32px;
  }
}

@include laptop {
  .projectsMap {
    height: 480px;
  }
}

.newBuildingWrapper {
  display: block;
  margin-top: 100px;
  position: relative;

  .cityMainTitle {
    font-weight: 600;
    font-size: 50px;
    line-height: 55px;
    color: $color-onyx;
  }

  .cityTitle {
    font-weight: 600;
    line-height: 117%;
    cursor: pointer;
    font-size: 47px;
    color: rgba(20, 20, 20, 0.6);
    padding-right: 20px;

    svg {
      position: absolute;
      margin-top: 9px;
      margin-left: 5px;
      font-size: 30px;

      @include desktop1024 {
        margin-top: 13px;
      }

      @include desktop1376 {
        margin-top: 17px;
      }

      @include desktop1600 {
        margin-top: 14px;
      }
    }
  }

  .citiesBlock {
    width: 447px;
    padding: 20px 40px;
    background: $color-white;
    box-shadow: 0 0 4px rgba(124, 124, 124, 0.25);
    border-radius: 8px;
    column-gap: 40px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: absolute;
    z-index: 5;
    top: 125px;
    left: 0;

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: $color-black;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        color: $color-tangelo;
      }
    }

    .activeCity {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: $color-tangelo;
      transition: 0.3s;
      cursor: pointer;
    }
  }

  .filtersBlock {
    display: flex;
    margin-top: 25px;
    padding-top: 15px;
    column-gap: 10px;
    justify-content: space-between;
    align-items: center;

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: $color-black;

      span {
        white-space: nowrap;
      }
    }
  }

  .projectsWrapper {
    margin-top: 24px;
    display: grid;
    column-gap: 31px;
    flex-wrap: wrap;

    .projectCard {
      width: 384px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      transition: 0.3s;

      h4 {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.025em;
        color: $color-onyx;
      }

      .projectAddress {
        display: flex;
        align-items: center;
        margin-top: 5px;

        div {
          flex-shrink: 0;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 8px;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: $color-onyx;
        }

        .walkManLogo {
          margin: 0 8px 0 20px;
        }
      }

      .projectImgBlock {
        position: relative;

        .projectHoverLinkIcon {
          width: 24px;
          height: 24px;
          position: absolute;
          top: 14px;
          right: 14px;
          display: none;
          z-index: 2;
        }

        .flatsBlock {
          display: flex;
          column-gap: 7px;
          position: absolute;
          bottom: 26px;
          left: 16px;
          background: rgba(255, 255, 255, 0.4);
          backdrop-filter: blur(16px);
          border-radius: 20px;
          padding: 7px 16px 7px 19px;
          z-index: 2;

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: $color-white;
          }
        }

        .projectImg {
          width: 100%;
          margin: 8px 0;
          height: 219px;
          border-radius: 8px;
          object-fit: cover;

          @media (min-width: 500px) {
            height: 300px;
          }

          @include laptop768 {
            height: 350px;
          }

          @media (min-width: 900px) {
            height: 450px;
          }

          @include desktop1024 {
            height: 211px;
          }

          @include desktop1376 {
            height: 219px;
          }
        }
      }

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.02em;
        color: $color-dark-lava;
      }

      &:hover {
        h4 {
          color: $color-tangelo;
        }

        .projectImgBlock {
          .projectHoverLinkIcon {
            display: block;
          }

          .projectImg {
            -webkit-filter: brightness(70%);
            filter: brightness(70%);
          }
        }
      }
    }

    @include desktop1024 {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .showMoreBtn {
    left: 613px;
    margin: 35px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: $color-black;
    padding: 14px 23px;
    cursor: pointer;
    background: $color-white;
    border-radius: 80px;
    border: none;
    transition: 0.3s;

    &:hover {
      background: $color-tangelo;
      color: $color-white;
    }

    @include desktop1600 {
      margin: 45px auto 0;
    }
    @include tablet{
      left: 613px;
      margin: 35px auto 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
    }
  }
}

@include desktop1600 {
  .newBuildingWrapper {
    .cityTitle {
      font-size: 40px;
      line-height: 55px;
    }

    .filtersBlock {
      margin-top: 30px;
      padding-top: 15px;
      column-gap: 20px;

      p {
        margin-right: 31px;
      }
    }

    .projectsWrapper {
      .projectCard {
        width: 435px;

        h4 {
          font-size: 25px;
          line-height: 24px;
        }

        .projectAddress {
          p {
            font-size: 16px;
            line-height: 24px;
          }

          .walkManLogo {
            margin: 0 8px 0 24px;
          }
        }

        .projectImgBlock {
          .projectImg {
            height: 215px;
            margin-top: 10px;
          }
        }

        span {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }

    .showMoreBtn {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

@include desktop {
  .newBuildingWrapper {
    .cityMainTitle {
      font-size: 40px;
      line-height: 45px;
      width: 330px;
    }

    .cityTitle {
      font-size: 32px;
      line-height: 55px;
    }

    .citiesBlock {
      width: 447px;
      padding: 20px;
      column-gap: 40px;
      row-gap: 10px;
      top: 92px;

      p {
        font-size: 16px;
        line-height: 24px;
      }

      .activeCity {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .filtersBlock {
      margin-top: 15px;
      padding-top: 15px;
    }

    .projectsWrapper {
      .projectCard {
        width: 296px;
        column-gap: 27px;

        .projectImgBlock {
          .projectImg {
            //height: 211px;
          }
        }
      }
    }

    .showMoreBtn {
      margin: 30px auto 0;
    }
  }
}

@include laptop {
  .newBuildingWrapper {
    margin-top: 100px;
    padding: 0;

    .cityMainTitle {
      font-size: 42px;
      line-height: 100%;
    }

    .citiesBlock {
      //left: 20px;
      //right: 20px;
    }

    .cityTitle {
      font-size: 35px;
      line-height: 100%;

      svg {
        font-size: 20px;
      }
    }

    .filtersBlock {
      margin-top: 46px;
      padding-top: 15px;
      flex-direction: column;
      align-items: start;

      p {
        font-size: 18px;
        margin-top: 50px;
      }
    }

    .projectsWrapper {
      .projectCard {
        width: 100%;
        margin: 40px 0;

        h4 {
          font-size: 35px;
          line-height: 100%;
        }

        .projectAddress {
          margin: 10px 0 16px 0;

          div {
            width: 16px;
            height: 16px;
          }

          p {
            font-size: 18px;
            line-height: 100%;
          }
        }

        .projectImgBlock {
          .flatsBlock {
            padding: 16px 32px;
            border-radius: 80px;
            column-gap: 14px;
            left: 32px;
            bottom: 44px;

            img {
              width: 20px;
              height: 22px;
            }

            p {
              font-size: 20px;
            }
          }

          .projectImg {
            //height: 450px;
            margin-bottom: 16px;
          }
        }

        span {
          font-size: 18px;
          line-height: 100%;
        }
      }
    }

    .showMoreBtn {
      margin: 35px auto 0;
      padding: 17px 24px;
      font-size: 16px;
      line-height: 20px;
      display: flex;
    }
  }
}

@include tablet {
  .newBuildingWrapper {
    padding: 0 20px;

    .citiesBlock {
      width: auto;
      left: 20px;
      right: 20px;
    }
  }
}

@include mobileL {
  .newBuildingWrapper {
    margin-top: 90px;
    padding: 0 20px;

    .cityMainTitle {
      font-size: 42px;
      line-height: 100%;
    }

    .cityTitle {
      font-size: 35px;
      line-height: 100%;

      svg {
        font-size: 20px;
      }
    }

    .filtersBlock {
      margin-top: 19px;
      padding-top: 15px;

      p {
        font-size: 16px;
        margin-top: 30px;
      }
    }

    .projectsWrapper {
      margin-top: 0;

      .projectCard {
        margin: 40px 0 0 0;

        h4 {
          font-size: 28px;
          line-height: 100%;
        }

        .projectAddress {
          margin: 6px 0 10px 0;

          div {
            width: 9px;
            height: 9px;
          }

          p {
            font-size: 16px;
            line-height: 100%;
          }
        }

        .projectImgBlock {
          .flatsBlock {
            padding: 9px 18px;
            column-gap: 8px;
            left: 18px;
            bottom: 36px;

            img {
              width: 10px;
              height: 16px;
            }

            p {
              font-size: 12px;
            }
          }

          .projectImg {
            height: 268px;
            margin-bottom: 9px;
          }
        }

        span {
          font-size: 20px;
          line-height: 100%;
        }
      }
    }

    .showMoreBtn {
      padding: 26px 15px;
      font-size: 16px;
    }
  }
}

@include mobileM {
  .newBuildingWrapper {
    margin-top: 80px;

    .cityMainTitle {
      font-size: 38px;
    }

    .cityTitle {
      font-size: 30px;

      svg {
        font-size: 12px;
      }
    }

    .filtersBlock {
      margin-top: 15px;
      padding-top: 15px;

      p {
        font-size: 14px;
        margin-top: 25px;
      }
    }

    .projectsWrapper {
      .projectCard {
        margin: 40px 0 0 0;

        h4 {
          font-size: 22px;
        }

        .projectAddress {
          margin: 6px 0 8px 0;

          div {
            width: 8px;
            height: 8px;
          }

          p {
            font-size: 14px;
          }
        }

        .projectImgBlock {
          .flatsBlock {
            padding: 9px 18px;
            column-gap: 8px;
            left: 16px;
            bottom: 24px;

            img {
              width: 10px;
              height: 16px;
            }

            p {
              font-size: 12px;
            }
          }

          .projectImg {
            height: 220px;
          }
        }

        span {
          // 18->16
          font-size: 16px;
        }
      }
    }

    .showMoreBtn {
      padding: 16px 24px;
      font-size: 14px;
    }

    .citiesBlock {
      top: 85px;
      left: 20px;
      right: 20px;
      row-gap: 25px;
      column-gap: 30px;
      padding: 20px 15px;
      width: auto;
    }
  }
}

.mapPopupBg {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);

  @include laptop {
    display: none;
  }
}

.mapPopup {
  position: absolute;
  z-index: 2;
  top: 31%;
  left: 50%;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0 0 4px rgba(124, 124, 124, 0.25);
  transform: translateX(-50%);

  @media (min-width: 600px) {
    top: 43%;
    width: 400px;
  }

  @include desktop1024 {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.mapPopupMedia {
  width: 100%;
  max-width: 100%;

  img {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
}

.mapPopupClose {
  position: absolute;
  right: 15px;
  top: 15px;
  border: 0;
  padding: 0;
  cursor: pointer;
  color: $color-tangelo;
  background-color: transparent;

  svg {
    display: block;
    width: 32px;
    height: 32px;
  }
}

.mapPopupContent {
  padding: 20px 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #141414;
}

.mapPopupTitle {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.025em;
}

.mapPopupDesc {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.mapPopupDescItem {
  display: flex;
  align-items: center;

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #AFADAC;
    margin-right: 8px;
  }

  &:not(:last-child) {
    margin-right: 20px;
  }

  svg {
    flex-shrink: 0;
    margin-right: 5px;
  }
}

.mapPopupList {
  margin: 0 -40px;
}

.mapPopupListItem {
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  background-color: rgba(20, 20, 20, 0.03);

  &:nth-child(2n) {
    background-color: #fff;
  }
}

.mapPopupLink {
  display: flex;
  align-items: center;
  margin-top: 15px;
  color: #FC4C02;

  svg {
    margin-left: 5px;
  }
}
