@import "src/scss/variables";
@import "../../../scss/mixins/breakpoint";

.articleMainBlock{
  display: block;
  @include laptop{
    margin: 0 40px;
  }
  @include mobileL {
    margin: 0 22px;
  }
  @include mobileM {
    margin: 0 22px;
  }
}

.articlesWrapper {
  margin-top: 100px;
  .articlesTitle{
    font-weight: 600;
    font-size: 50px;
    line-height: 55px;
    color: $color-onyx;
  }
  .articleCards {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    justify-content: space-between;
    .selectionCard {
      display: flex;
      flex-direction: column;
      width: 49%;
      border: none;
      border-radius: 8px;
      background: $color-white;
      transition: 0.3s;
      text-align: left;
      cursor: pointer;
      &:hover{
        .cardDescription{
          .cardBtn{
            background: $color-tangelo;
            color: $color-white;
          }
        }
        .selectionImg1{
          -webkit-filter: brightness(70%);
          filter: brightness(70%);
        }
        .selectionImg2{
          -webkit-filter: brightness(70%);
          filter: brightness(70%);
        }
      }
      .selectionImg1 {
        flex-shrink: 0;
        width: 100%;
        height: 172px;
        border-radius: 8px 8px 0 0;
        transition: 0.3s;
        background: url("../../../assets/images/pages/article-3/article3-main.jpg") no-repeat center;
        background-size: cover;
      }
      .selectionImg2 {
        flex-shrink: 0;
        width: 100%;
        height: 172px;
        border-radius: 8px 8px 0 0;
        transition: 0.3s;
        background: url("../../../assets/images/pages/article-2/article2Img-main.jpg") no-repeat center;
        background-size: cover;
      }
      .cardDescription {
        display: flex;
        flex-direction: column;
        padding: 25px 40px 30px 40px;
        height: 100%;
        p {
          margin-bottom: 20px;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.02em;
          color: $color-onyx;
        }
        .cardBtn {
          margin-top: auto;
          background: $color-gray-button;
          border-radius: 80px;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 10px;
          font-weight: 500;
          font-size: 14px;
          padding: 16px 23px;
          align-self: flex-start;
          line-height: 14px;
          color: $color-onyx;
          transition: 0.3s;
          svg {
            stroke-width: 20px;
          }
        }
      }
    }
  }
}
.showAllBtn{
  background: $color-white;
  border-radius: 80px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: $color-black;
  border: none;
  margin: 52px auto 0;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  padding: 17px 24px;
  justify-content: center;
  align-items: center;
  &:hover{
    background: $color-tangelo;
    color: $color-white;
  }
}

@include desktop1600{
  .articlesWrapper{
    .articleCards{
      .selectionCard{
        width: 49%;
        .cardDescription{
          padding: 25px 40px 30px 40px;
          .cardBtn{
            padding: 15px 21px;
            font-size: 16px;
            line-height: 20px;
          }
        }

        .selectionImg1,
        .selectionImg2 {
          height: 200px;
        }
      }
    }
    .showAllBtn{
      padding: 20px 25px;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

@include desktop{
  .articlesWrapper{
    .articlesTitle{
      font-size: 40px;
      line-height: 45px;
    }
    .articleCards{
      margin-top: 30px;
      .selectionCard{
        width: 49%;

        .cardDescription{
          padding: 20px 20px 25px 20px;

          .cardBtn {
            padding: 15px 23px;
          }
        }
      }
    }

    .showAllBtn{
      padding: 20px 25px;
    }
  }
}

@include laptop {
  .articlesWrapper{
    margin-top: 160px;
    .articlesTitle{
      font-size: 68px;
      line-height: 100%;
    }
    .articleCards{
      flex-direction: column;
      row-gap: 70px;
      margin-top: 60px;
      .selectionCard{
        width: 100%;
        .selectionImg{
          height: 315px;
        }
        .cardDescription{
          padding: 41px 51px 51px 51px;
          p{
            font-size: 37px;
            line-height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  .showAllBtn{
    padding: 30px 47px;
    font-size: 16px;
  }
}

@include tablet{
  .articlesWrapper{
    margin-top: 160px;
    .articlesTitle{
      font-size: 42px;
      line-height: 100%;
    }
    .articleCards{
      .selectionCard{
        .selectionImg{
          height: 315px;
        }
        .cardDescription{
          padding: 30px 30px 51px 30px;
          p{
            font-size: 35px;
          }
        }
      }
    }
  }
  .showAllBtn{
    padding: 30px 47px;
    font-size: 16px;
  }
}

@media (max-width: 550px){
  .articlesWrapper{
    margin-top: 90px;
    .articlesTitle{
      font-size: 40px;
    }
    .articleCards{
      row-gap: 40px;
      margin-top: 34px;
      .selectionCard{
        .selectionImg{
          height: 175px;
        }
        .cardDescription{
          padding: 22px 28px 28px 28px;
          p{
            font-size: 24px;
          }
          .cardBtn{
            padding: 18px 22px;
            font-size: 16px;
          }
        }
      }
    }
    .showAllBtn{
      padding: 17px 26px;
      // 18->16
      font-size: 16px;
    }
  }
}

@include mobileM{
  .articlesWrapper{
    margin-top: 80px;
    //padding: 0 20px;
    .articlesTitle{
      font-size: 36px;
    }
    .articleCards{
      row-gap: 20px;
      margin-top: 30px;
      .selectionCard{
        .selectionImg{
          height: 154px;
        }
        .cardDescription{
          padding: 25px 25px 30px 25px;
          p{
            font-size: 20px;
            line-height: 24px;
          }
          .cardBtn{
            padding: 16px 18px;
            font-size: 14px;
          }
        }
      }
    }
    .showAllBtn{
      margin-top: 35px;
      padding: 15px 23px;
      font-size: 14px;
    }
  }
}

.articlePage {
  position: relative;

  b {
    font-weight: 500;
  }
}

.articleButton {
  align-items: center;
  background-color: #fc4c02;
  border: 2px solid #fc4c02;
  border-radius: 80px;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  outline: none;
  padding: 15px 20px;
  position: relative;
  text-align: center;
  transition: $duration-normal;

  &:hover {
    background-color: #fff;
    color: #000;
  }
}




