.rc-slider{
    width: 96%;
    left: 8px;
}
.rc-slider-rail {
    position: absolute;
    width: 96%;
    bottom: 15px;
    left: 8px;
    background-color: transparent !important;
    border-radius: 6px;
    height: 1px;
}
.rc-slider-track {
    position: absolute;
    height: 1px;
    border-radius: 6px;
    background-color: transparent !important;
    opacity: 100% !important;
}
.mka__range-alignment {
    display: flex;
    flex-direction: column;
    justify-content:
            flex-start; float: left;
    background-color: transparent;
    padding: 10px;
}
.mka__range-text {
    display: flex;
    gap: 10px;
    color: white;
}
.rc-slider-handle{
    width: 30px;
    height: 16px;
    top: -4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16) !important;
    border-radius: 16px;
    border: none;
    background: white;
    opacity: 1;
}
