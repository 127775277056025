@import "../../scss/variables";
@import "../../scss/mixins/breakpoint";

.profit {
  margin: 80px 0 100px;
  padding: 60px 20px;
  border-radius: 8px;
  background: #E3D3F7;

  @include desktop1024 {
    display: grid;
    grid-template-columns: auto 463px;
    margin: 130px 0;
    padding: 35px 56px;
  }

  @include desktop1376 {
    grid-template-columns: auto 592px;
    padding: 40px 80px;
  }

  @include desktop1600 {
    grid-template-columns: auto 706px;
    padding: 40px 107px;
  }
}

.title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 36px;
  line-height: 108%;
  color: #141414;

  @include desktop1024 {
    margin-bottom: 0;
    font-size: 40px;
    line-height: 112%;
  }

  @include desktop1376 {
    font-size: 50px;
    line-height: 110%;
  }
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #141414;

  @include desktop1024 {
    // 18->16
    font-size: 16px;
    line-height: 156%;
  }

  @include desktop1376 {
    // 18->16
    font-size: 16px;
    line-height: 178%;
  }
}
