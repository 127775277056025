@import "src/scss/variables";
@import "../../../scss/mixins/breakpoint";

@mixin cardDescription{
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.04em;
  color: $color-black;
  transition: 0.3s;
  @include desktop{
    font-size: 22px;
    line-height: 26px;
  }
  @include desktop1600{
    font-size: 32px;
    line-height: 32px;
  }
}

.programsWrapper{
  margin-top: 100px;
  h3{
    font-weight: 500;
    font-size: 40px;
    line-height: 100%;
    width: 50%;
  }
  .programsCards{
    display: none;
    justify-content: space-between;
    margin-top: 34px;

    .programsCardsBg {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: 50% 15% no-repeat;
      background-size: cover;
    }

    .firstCard,
    .secondCard,
    .thirdCard {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      //width: 400px;
      width: 100%;
      height: 445px;
      padding: 30px;
      cursor: pointer;
      border-radius: 8px;
      overflow: hidden;
      transition: 0.3s;
      box-shadow: #3a3a3a;

      p{
        @include cardDescription
      }

      &:hover{
        opacity: 0.7;
      }
    }
    .secondCard{
      p {
        color: #323232;
      }

      //span {
      //  color: #90C500;
      //}

      .programsCardsNumb {
        border-radius: 250px;
        padding: 2px 15px;
        color: $color-white;
        background: #FC4C02;
      }

      .programsCardsDate {
        display: block;
        margin-top: 5px;
        font-size: 20px;
        line-height: 20px;
        color: #8C8279;
      }

      .programsCardsButton {
        margin-top: auto;
        border-radius: 74px;
        padding: 6px 16px;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.01em;
        color: $color-white;
        background: #8C8279;
      }
    }

    @include laptop768 {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(3, 1fr);
    }

    @include desktop1024 {
      grid-gap: 26px;
    }

    @include desktop1376 {
      grid-gap: 33px;
    }

    @include desktop1600 {
      grid-gap: 37px;
    }
  }
}

@include desktop1600{
  .programsWrapper{
    .programsCards{
      margin-top: 35px;

      .firstCard,
      .secondCard,
      .thirdCard {
       // width: 435px;
        height: 448px;
        padding: 40px;
      }
    }
  }
}

@include desktop{
  .programsWrapper{
    h3{
      font-size: 30px;
      line-height: 34px;
    }
    .programsCards{
      margin-top: 30px;

      .firstCard,
      .secondCard,
      .thirdCard {
        height: 400px;
        //width: 300px;
        padding: 20px;
      }
    }
  }
}

@include laptop{
  .programsWrapper{
    margin-top: 100px;
    margin-bottom: 50px;
    padding: 0 40px;
    h3{
      width: 100%;
      font-size: 42px;
      line-height: 100%;
    }
  }
}

@include mobileL{
  .programsWrapper{
    margin-top: 90px;
    margin-bottom: 80px;
    padding: 0 22px;
    h3{
      font-size: 34px;
    }
    .programsCards{
      margin-top: 60px;
      overflow-x: scroll;

      .firstCard,
      .secondCard,
      .thirdCard{
        width: 100%;
        padding: 30px 20px;
      }
    }
  }
}

@include mobileM{
  .programsWrapper{
    margin-top: 80px;
    margin-bottom: 86px;
    padding: 0 20px;
    h3{
      font-size: 30px;
    }
    .programsCards{
      margin-top: 60px;
      overflow-x: scroll;

      .firstCard,
      .secondCard,
      .thirdCard {
        width: 100%;
        padding: 30px 20px;
      }
    }
  }
}
