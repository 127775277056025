@import "src/scss/variables";
@import "../../../scss/mixins/breakpoint";

.rangeFilter{
  position: relative;
  width: 384px;
  .rageFields{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $color-timberwolf;
    border-radius: 32px;
    padding: 16px 24px;
    max-height: 48px;
    font-size: 14px;
    line-height: 16px;
    color: $color-onyx;
    background-color: $color-white-smoke;
    .rageFieldStart{
      display: flex;
      align-items: center;
      border-right: 1px solid $color-timberwolf;
      height: 24px;
      width: 50%;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type=number] {
        -moz-appearance: textfield;
      }
      label{
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $color-onyx;
      }
      input{
        border: none;
        background: transparent;
        margin-left: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $color-black;
        outline: none;
      }
    }
    .rageFieldEnd{
      display: flex;
      align-items: center;
      height: 24px;
      margin-left: 24px;
      width: 50%;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type=number] {
        -moz-appearance: textfield;
      }
      label{
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $color-onyx;
      }
      input{
        border: none;
        background: transparent;
        margin-left: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $color-black;
        width: 100%;
        outline: none;
      }
    }
  }
  .rangeSlider{
    position: absolute;
    left: 13px;
    bottom: 0;
    width: 93%;
    height: 0;
    input{
      background-color: transparent;
      pointer-events: none;
      z-index: 2;
      width: 50%;
    }
  }
}

@include laptop{
  .rangeFilter{
    position: relative;
    width: 100%;
    .rageFields{
      border-radius: 60px;
      padding: 15px 20px;
      max-height: 60px;
      .rageFieldStart{
        height: 48px;
        label{
          font-size: 16px;
          line-height: 100%;
        }
        input{
          margin-left: 16px;
          font-size: 16px;
          line-height: 100%;
        }
      }
      .rageFieldEnd{
        height: 48px;
        label{
          font-size: 16px;
          line-height: 100%;
        }
        input{
          margin-left: 16px;
          font-size: 16px;
          line-height: 100%;
        }
      }
    }
    .rangeSlider{
      left: 20px;
    }
  }
}
@include mobileL{
  .rangeFilter{
    .rageFields{
      border-radius: 32px;
      padding: 17px 23px;
      max-height: 54px;
      .rageFieldStart{
        height: 27px;
        label{
          font-size: 16px;
        }
        input{
          margin-left: 9px;
          font-size: 16px;
        }
      }
      .rageFieldEnd{
        height: 27px;
        margin-left: 20px;
        label{
          font-size: 16px;
        }
        input{
          margin-left: 9px;
          font-size: 16px;
        }
      }
    }
    .rangeSlider{
      left: 13px;
    }
  }
}
@include mobileM{
  .rangeFilter{
    .rageFields{
      border-radius: 32px;
      padding: 15px;
      max-height: 48px;
      .rageFieldStart{
        height: 24px;
        label{
          font-size: 14px;
        }
        input{
          margin-left: 7px;
          font-size: 14px;
        }
      }
      .rageFieldEnd{
        height: 24px;
        label{
          font-size: 14px;
        }
        input{
          margin-left: 7px;
          font-size: 14px;
        }
      }
    }
    .rangeSlider{
      left: 10px;
    }
  }
}
