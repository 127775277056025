@import "src/scss/variables";
@import "../../scss/mixins/breakpoint";

@function vm($value, $base: 375) {
  @return $value / $base * 100vmin;
}

.articleContentItem {
  display: grid;
  column-gap: 110px;
  grid-template-areas:
		"top pic"
		"bottom pic";
  grid-template-columns: 750px 1fr;

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: $color-medium-jungle-green;
  }

  a {
    border-bottom: 1px solid $color-tangelo;
    color: $color-tangelo;
    transition: 0.3s;

    @include desktop1024 {
      &:hover {
        border-bottom: 1px solid transparent;
      }
    }
  }

  p:not(:first-child) {
    margin-top: 40px;

    @include devices {
      margin-top: 40px;
    }
    @include mobileL {
      margin-top: 25px;
    }
  }

  @include devices {
    grid-template-areas:
			"top"
			"pic"
			"bottom";
    grid-template-columns: 1fr;
  }

  @include laptop-2 {
    column-gap: 48px;
    grid-template-columns: 574px 1fr;
  }

  @include desktop1600 {
    column-gap: 120px;
    grid-template-columns: 850px 1fr;
  }
}

.articleContentItemTextTop {
  grid-area: top;

  &:last-child {
    margin-bottom: 0;

    @include devices {
      margin-bottom: 0;
    }
  }

  @include mobileL {
    p{
      font-size: 16px;
      line-height: 24px;
    }
  }

  @include mobileL {
    p{
      //width: 90%;
    }
  }
}

.articleContentItemIllustartion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: pic;

  .articleTitle {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 40px;
    line-height: 100%;
    letter-spacing: -0.04em;
    color: $color-tangelo;

    @include devices {
      font-size: 30px;
    }
  }

  .articleText {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    //letter-spacing: -0.02em;
  }

  @include devices {
    margin-top: 40px;
  }

  @include laptop-2 {
    margin-top: -57px;
  }

  @media (min-width: 1376px) {
    margin-top: -51px;
  }

  @include desktop1600 {
    margin-top: 25px;
  }
}

.articleContent {
  margin: 40px 80px 50px;
  max-width: 1384px;

  .articleContentTitle {
    margin-top: 50px;
    margin-bottom: 40px;
    max-width: 750px;
    font-size: 40px;
    line-height: 100%;
    font-weight: 500;
    color: $color-tangelo;

    @include mobileL {
      margin-top: 50px;
    }

    @include devices {
      margin-bottom: 25px;
      font-size: 30px;
    }

    @include laptop-2 {
      max-width: 574px;
      font-size: 36px;
    }

    @include desktop1600 {
      margin-top: 60px;
      max-width: 850px;
    }
  }

  @include devices {
    margin: 20px 20px 50px;
  }

  @include laptop-2 {
    margin: 0 35px 100px 35px;
  }

  @include desktop1600 {
    margin-right: auto;
    margin-left: auto;
  }
}

.articlePage {
  img {
    max-width: 100%;
    user-select: none;
    vertical-align: top;
    border-style: none;
  }
}

.articleIntro {
  //position: relative;
  margin: 30px 80px 50px;
  max-width: 1384px;
  //opacity: 0;

  h1 {
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 25px;
    font-size: 50px;
    line-height: 118%;

    b {
      font-weight: 600;
    }

    @include devices {
      font-size: 38px;
      line-height: 111%;
    }

    @include laptop-2 {
      margin-bottom: 15px;
      font-size: 44px;
      line-height: 109%;
    }

    @include desktop1600 {
      font-size: 77px;
      line-height: 103%;
    }
  }

  .articleLead {
    font-weight: 300;
    font-size: 28px;
    line-height: 143%;
    letter-spacing: -0.02em;
    color: #000;
    margin-bottom: 30px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    row-gap: 40px;

    @include devices {
      margin-bottom: 40px;
      font-size: 22px;
      line-height: 136%;
      margin-top: 40px;
      row-gap: 40px;
    }

    @include mobileL{
      row-gap: 25px;
    }

    @include laptop-2 {
      margin-bottom: 40px;
      font-size: 24px;
      line-height: 142%;
    }
  }

  .articleIntroImg {
    overflow: hidden;
    border-radius: 8px;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 8px;

      @include desktop-1600 {
        height: 580px;
      }
      @include desktop-xl {
        height: 580px;
      }
      @include desktop1440 {
        height: 500px;
      }
      @include desktop {
        height: 400px;
      }
      @include laptop {
        height: 399px;
      }
      @include mobileL {
        height: 225px;
      }
    }
  }

  @include devices {
    margin: 0 20px 40px;
  }

  @include laptop-2 {
    margin: 0 35px 100px 35px;
  }

  @include desktop1600 {
    position: relative;
    margin: 0 auto 80px;
  }
}

.articleProduct {
  background-color: $color-eggshell;
  //opacity: 0;

  .articleProductContainer {
    margin-right: 0;
    margin-bottom: 50px;
    margin-left: 0;
    padding: 75px 93px;

    @include devices {
      margin-bottom: 80px;
      padding: 80px 20px;
    }

    @include laptop-2 {
      padding: 75px 35px;
    }

    @include desktop1600 {
      margin-right: auto;
      margin-left: auto;
      padding: 75px 0;
      max-width: 1384px;
    }
  }

  .articleProductRow {
    display: grid;
    column-gap: 92px;
    grid-template-columns: 463px 543px;

    @include mobile {
      display: flex;
      column-gap: 50px;
      flex-direction: column;
    }

    @include tablet-2 {
      column-gap: 40px;
      grid-template-columns: 300px 1fr;
    }

    @include laptop-2 {
      column-gap: 82px;
      grid-template-columns: 367px 471px;
    }

    @include desktop1600 {
      column-gap: 107px;
      grid-template-columns: 430px 591px;
    }
  }

  .articleProductRowTop {
    display: grid;
    column-gap: 92px;
    grid-template-columns: 463px 543px;
    margin-bottom: 60px;

    .articleProductTitle {
      @include laptop768 {
        margin-bottom: 0;
      }
    }

    .articleProductText {
      margin-bottom: 0;
    }

    @include mobile {
      display: flex;
      column-gap: 50px;
      flex-direction: column;
    }

    @include devices {
      margin-bottom: 25px;
    }

    @include tablet-2 {
      margin-bottom: 60px;
      column-gap: 40px;
      grid-template-columns: 300px 1fr;
    }

    @include laptop-2 {
      column-gap: 82px;
      grid-template-columns: 367px 471px;
    }

    @include desktop1600 {
      margin-bottom: 80px;
      column-gap: 107px;
      grid-template-columns: 430px 591px;
    }
  }

  .articleProductCol {
    &:first-child {
      @include laptop-2 {
        .articleProductText {
          margin-bottom: 100px;
          font-weight: 300;
          font-size: 18px;
          line-height: 28px;
          letter-spacing: -0.02em;
          color: #000000;
        }
      }

      @include mobile {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }

  .articleProductTitle {
    margin-top: 0;
    margin-bottom: 60px;
    font-weight: 500;
    font-size: 40px;
    line-height: 100%;
    max-width: 450px;

    @include devices {
      margin-bottom: 50px;
      font-size: 30px;
    }

    @include laptop-2 {
      margin-bottom: 60px;
      font-size: 36px;
      max-width: 370px;
    }

    @include desktop1600 {
      margin-bottom: 80px;
      font-size: 40px;
      max-width: 470px;
      letter-spacing: -0.04em;
    }
  }

  .articleProductText {
    margin-bottom: 60px;
    font-weight: 400;
    // 18->16
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: $color-medium-jungle-green;

    &:last-child {
      margin-bottom: 0;
    }

    @include devices {
      margin-bottom: 25px;
      font-size: 16px;
      line-height: 24px;
    }

    @include laptop-2 {
      margin-bottom: 60px;
    }

    @include desktop1600 {
      margin-bottom: 60px;
    }
  }

  .articleProductName {
    display: block;
    font-weight: 500;
  }

  .articleProductTextTop {
    margin-top: -100px;

    @include devices {
      margin-top: 0;
    }
  }

  .articleProductTextTop2 {
    @include laptop-2 {
      margin-top: -70px;
    }
  }

  .articleProductImg {
    border-radius: 8px;
    object-fit: cover;
    img {
      border-radius: 8px;
      object-fit: cover;
      width: 463px;
      height: 315px;

      @include devices {
        width: 100%;
        height: 225px;
      }

      @include laptop-2 {
        width: 367px;
        height: 250px;
      }

      @include desktop1600 {
        width: 430px;
        height: 290px;
      }
    }

    @include devices {
      margin-bottom: 50px;
    }

    @include laptop768 {
      margin-top: 7px;
    }
  }

  .button {
    @include devices {
      margin-top: 20px;
      width: 100%;
      justify-content: center;
    }
  }
}

.articleProductViolet {
  background: $color-pale-lavender;
}

.articleDescrImg {
  overflow: hidden;
  border-radius: 8px;
  margin-top: 40px;
  margin-bottom: 40px;

  @include tablet{
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @include mobileL {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  img {
    width: 100%;
    object-fit: cover;
    border-radius: 8px;

    @include desktop-1600 {
      height: 580px;
    }
    @include desktop-xl {
      height: 580px;
    }
    @include desktop1440 {
      height: 500px;
    }
    @include desktop {
      height: 400px;
    }
    @include laptop {
      height: 399px;
      width: 100%;
    }
    @include mobileL {
      height: 225px;
    }
  }
}
