@import "src/scss/variables";
@import "../../../scss/mixins/breakpoint";

.selectionsWrapper {
  margin-top: 100px;

  .selectionsTitle{
    font-weight: 600;
    font-size: 50px;
    line-height: 55px;
    color: $color-onyx;
  }
  .selectionCards {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    column-gap: 24px;
    row-gap: 40px;
    .selectionCard {
      display: flex;
      flex-direction: column;
      width: 32%;
      border-radius: 8px;
      background: $color-white;
      cursor: pointer;
      &:hover{
        .cardBtn{
          background: $color-tangelo;
          color: $color-white;
        }
        .selectionImg{
          -webkit-filter: brightness(70%);
          filter: brightness(70%); }
        }
      }
      .selectionImg {
        width: 100%;
        height: 172px;
        border-radius: 8px 8px 0 0;
        transition: 0.3s;
        object-fit: cover;
      }
      .cardDescription {
        display: flex;
        flex-direction: column;
        padding: 25px 25px 30px 25px;
        height: 100%;
        h3 {
          font-weight: 500;
          font-size: 26px;
          line-height: 100%;
          letter-spacing: -0.04em;
          color: $color-onyx;
        }
        p {
          margin-bottom: 25px;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.02em;
          color: $color-onyx;
          margin-top: 12px;
        }
        .cardBtn {
          margin-top: auto;
          background: $color-gray-button;
          border-radius: 80px;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-start;
          column-gap: 10px;
          font-weight: 500;
          padding: 16px 20px;
          font-size: 14px;
          line-height: 14px;
          color: $color-onyx;
          cursor: pointer;
          transition: 0.3s;
          svg {
            stroke-width: 20px;
            margin-top: 1px;
          }
        }
      }
    }
  }
.showAllBtn{
  background: $color-white;
  border-radius: 80px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: $color-black;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 52px auto 0 auto;
  padding: 17px 24px;
  transition: 0.3s;
  cursor: pointer;
  &:hover{
    background: $color-tangelo;
    color: $color-white;
  }
}

@include desktop1600{
  .selectionsWrapper{
    .selectionCards{
      .selectionCard{
        .selectionImg{
          height: 266px;
        }
        .cardDescription{
          padding: 25px 40px 30px 40px;
          h3{
            font-size: 30px;
            line-height: 34px;
          }
          p{
            margin-bottom: 20px;
            font-size: 20px;
            line-height: 24px;
          }
          .cardBtn{
            padding: 15px 21px;
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
    .showAllBtn{
      padding: 20px 25px;
    }
  }
}

@include desktop{
  .selectionsWrapper{
    .selectionCards{
      margin-top: 30px;
      column-gap: 18px;
      .selectionCard{
        .selectionImg{
          height: 172px;
        }
        .cardDescription{
          padding: 20px 20px 25px 20px;
          h3{
            font-size: 26px;
          }
          p{
            margin-top: 10px;
          }
        }
      }
    }
    .cardBtn{
      padding: 17px 24px;
    }

    .showAllBtn{
      margin-top: 30px;
      padding: 15px 23px;
    }
  }
}

@include laptop{
  .selectionsWrapper{
    margin-top: 100px;
    padding: 0 40px;
    .selectionsTitle{
      font-size: 42px;
      line-height: 100%;
    }
    .selectionCards{
      flex-direction: column;
      margin-top: 40px;
      row-gap: 70px;
      column-gap: 18px;
      .selectionCard{
        width: 100%;
        .selectionImg{
          height: 353px;
        }
        .cardDescription{
          padding: 30px 30px 50px;
          h3{
            font-size: 35px;
            line-height: 100%;
          }
          p{
            font-size: 25px;
            line-height: 100%;
          }
          .cardBtn{
            //margin-top: 40px;
            padding: 20px 25px;
            font-size: 16px;
          }
        }
      }
    }
    .showAllBtn{
      padding: 30px 47px;
      font-size: 30px;
    }
  }
}

@include mobileL{
  .selectionsWrapper{
    margin-top: 90px;
    padding: 0 22px;
    .selectionsTitle{
      font-size: 40px;
      line-height: 100%;
    }
    .selectionCards{
      margin-top: 34px;
      row-gap: 40px;
      .selectionCard{
        .selectionImg{
          height: 196px;
        }
        .cardDescription{
          padding: 28px 28px 34px 28px;
          h3{
            font-size: 30px;
          }
          p{
            font-size: 24px;
          }
          .cardBtn{
            padding: 18px 26px;
            font-size: 16px;
          }
        }
      }
    }
    .showAllBtn{
      padding: 17px 26px;
      // 18->16
      font-size: 16px;
    }
  }
}

@include mobileM{
  .selectionsWrapper{
    margin-top: 80px;
    padding: 0 20px;
    .selectionsTitle{
      font-size: 36px;
    }
    .selectionCards{
      margin-top: 30px;
      row-gap: 20px;
      .selectionCard{
        .selectionImg{
          height: 172px;
        }
        .cardDescription{
          padding: 25px 25px 30px 25px;
          h3{
            font-size: 25px;
            line-height: 25px;
          }
          p{
            font-size: 20px;
            line-height: 24px;
          }
          .cardBtn{
            padding: 17px 23px;
            font-size: 14px;
          }
        }
      }
    }
    .showAllBtn{
      margin-top: 35px;
      padding: 17px 23px;
      font-size: 14px;
    }
  }
}


