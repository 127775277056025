@import "../../../scss/variables";
@import "../../../scss/mixins/breakpoint";

.article-page {
  position: relative;
}

.button-return {
  @include laptop {
    top: -40px;
    left: 35px;
  }

  @include desktop-xl {
    top: -42px;
    left: 52px;
  }
}

.general-project {
  column-gap: 34px;
  grid-template-columns: 1fr 708px;
  margin: 0 33px 100px 94px;
  max-width: 1384px;

  h1 {
    @include devices {
      margin-top: 22px;
    }

    @media (min-width: 1376px) and (max-width: 1599px) {
      margin-top: 23px;
      margin-bottom: 18px;
      font-size: 50px;
    }

    @include desktop-xl {
      margin-top: -5px;
    }
  }

  .lead {
    @media (min-width: 1376px) and (max-width: 1599px) {
      max-width: 495px;
    }

    @include desktop-xl {
      max-width: 417px;
    }
  }

  .general-picture {
    @media (min-width: 1376px) and (max-width: 1599px) {
      margin-top: -17px;
      height: 455px;
    }
  }

  @include devices {
    margin: 20px 20px 80px;
  }

  @include laptop {
    grid-template-columns: 1fr 540px;
    margin: 0 35px 100px 35px;
  }

  @media (min-width: 1376px) and (max-width: 1599px) {
    grid-template-columns: 1fr 655px;
  }

  @include desktop-xl {
    column-gap: 54px;
    grid-template-columns: 1fr 644px;
    margin-bottom: 80px;
    margin-right: auto;
    margin-left: auto;
  }
}

.product {
  img {
    @media (min-width: 1376px) and (max-width: 1599px) {
      height: 340px;
      width: 100%;
    }
  }

  .title {
    @media (min-width: 1376px) and (max-width: 1599px) {
      margin-top: 44px;
      letter-spacing: -0.04em;
    }

    @include desktop-xl {
      margin-top: 12px;
      letter-spacing: -0.04em;
    }
  }

  .desc {
    @media (min-width: 1376px) and (max-width: 1599px) {
      max-width: 422px;
    }
  }

  .button {
    @media (min-width: 1376px) and (max-width: 1599px) {
      margin-bottom: 46px;
    }

    @include desktop-xl {
      padding: 21px;
    }
  }

  @media (min-width: 1376px) {
    margin-bottom: 100px;
  }

  @include desktop-xl {
    align-items: center;
  }
}

.articles {
  max-width: 1384px;

  h2 {
    @include laptop {
      margin-bottom: 30px;
      font-size: 40px;
      line-height: 112%;
    }
  }

  @include desktop-xl {
    margin-right: auto;
    margin-left: auto;
  }
}

.offer {
  border-radius: vc(8);
  padding: vc(25) vc(40);

  h2 {
    margin-top: vc(16);
    margin-bottom: vc(10);

    @include devices {
      margin-top: 0;
      margin-bottom: vc(10);
    }

    @include laptop {
      margin-bottom: vc(5);
      font-size: vc(40);
      line-height: 112%;
    }
  }

  @include devices {
    min-height: vc(205);
  }

  @include desktop-xl {
    padding: vc(40) vc(55);
  }
}

.desc {
  font-size: vc(24);
  line-height: 125%;
  letter-spacing: -0.02em;

  @include devices {
    margin-bottom: vc(30);
    font-weight: 400;
    font-size: vc(22);
    line-height: 136%;
  }

  @include laptop {
    font-size: 20px;
    line-height: 150%;
  }
}

.illustration {
  border-radius: 8px;
  width: 100%;

  @include devices {
    min-height: vc(205);
  }

  @include laptop {
    height: 310px;
    object-fit: cover;
  }
}

.article-content-item {
  display: grid;
  column-gap: 110px;
  grid-template-areas:
    "top pic"
    "bottom pic";
  grid-template-columns: 750px 1fr;

  p {
    font-weight: 400;
    // 18->16
    font-size: 16px;
    line-height: 178%;
    letter-spacing: -0.02em;
    color: $color-medium-jungle-green;

    @include devices {
      font-size: 16px;
      line-height: 150%;
    }

    @include laptop {
      line-height: 156%;
    }
  }

  p:not(:first-child) {
    margin-top: 80px;

    @include devices {
      margin-top: 40px;
    }
  }

  @include devices {
    grid-template-areas:
      "top"
      "pic"
      "bottom";
    grid-template-columns: 1fr;
  }

  @include laptop {
    column-gap: 48px;
    grid-template-columns: 574px 1fr;
  }

  @include desktop-xl {
    column-gap: 120px;
    grid-template-columns: 847px 1fr;
  }
}

.article-content-item__text--top {
  grid-area: top;

  &:last-child {
    margin-bottom: 0;

    @include devices {
      margin-bottom: 0;
    }
  }
}

.article-content-item__text--bottom {
  grid-area: bottom;
  margin-top: 80px;

  @include devices {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.article-content-item__illustartion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: pic;

  .title {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 40px;
    line-height: 100%;
    letter-spacing: -0.04em;
    color: $color-tangelo;

    @include devices {
      font-size: 30px;
    }
  }

  .text {
    font-weight: 500;
    font-size: 16px;
    line-height: 138%;
    letter-spacing: -0.02em;

    @include devices {
      line-height: 156%;
    }
  }

  @include devices {
    margin-top: 40px;
  }

  @include laptop {
    margin-top: -57px;
  }

  @media (min-width: 1376px) {
    margin-top: -51px;
  }

  @include desktop-xl {
    margin-top: 25px;
  }
}

.article-content-item__illustartion--2 {
  .title {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @include laptop {
    margin-top: -25px;
  }

  @media (min-width: 1376px) {
    margin-top: -30px;
  }

  @include desktop-xl {
    margin-top: 15px;
  }
}

.article-content {
  margin: 80px 80px 100px;

  @include devices {
    margin: 20px 20px 80px;
  }

  @include laptop {
    margin: 0 35px 100px 35px;
  }

  @include desktop-xl {
    margin-right: auto;
    margin-left: auto;
  }
}

.article-content-item__picture {
  margin-top: 100px;
  margin-bottom: 100px;

  img {
    margin-bottom: 10px;
    border-radius: 4px;
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    object-fit: cover;

    @include devices {
      margin-bottom: 5px;
      height: 205px;
    }

    @include laptop {
      height: 558px;
    }
  }

  figcaption {
    font-weight: 400;
    font-style: italic;
    font-size: 20px;
    line-height: 160%;
    opacity: 0.6;

    @include devices {
      font-size: 16px;
    }

    @include laptop {
      // 18->16
      font-size: 16px;
    }
  }

  @include devices {
    margin-top: 80px;
    margin-bottom: 40px;
  }
}

.product-wrap {
  background-color: $color-eggshell;
}

.product {
  display: grid;
  column-gap: 70px;
  grid-template-columns: 665px 1fr;
  margin-right: 0;
  margin-bottom: 80px;
  margin-left: 0;
  padding: 90px 80px 90px 80px;

  .title {
    margin-top: 0;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 40px;
    line-height: 100%;

    @include devices {
      margin-bottom: 10px;
      font-size: 30px;
    }

    @include laptop {
      margin-top: 15px;
      margin-bottom: 5px;
      max-width: 370px;
      font-size: 36px;
    }

    @include desktop-xl {
      margin-bottom: 15px;
      font-size: 40px;
    }
  }

  .desc {
    margin-bottom: 75px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.02em;

    @include devices {
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 150%;
    }

    @include laptop {
      margin-bottom: 50px;
      // 18->16
      font-size: 16px;
      line-height: 156%;
    }

    @include desktop-xl {
      margin-bottom: 50px;
      // 18->16
      font-size: 16px;
      line-height: 178%;
    }
  }

  .button {
    @include devices {
      justify-content: center;
      margin-bottom: 50px;
      padding-top: 16px;
      padding-bottom: 15px;
      width: 100%;
      text-align: center;
    }

    @include laptop {
      margin-bottom: 17px;
    }
  }

  img {
    border-radius: 8px;
    min-height: 205px;
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    object-fit: cover;

    @include laptop {
      min-height: 320px;
    }
  }

  @include devices {
    display: flex;
    column-gap: 50px;
    flex-direction: column-reverse;
    padding: 60px 20px;
  }

  @include laptop {
    margin-bottom: 100px;
    column-gap: 43px;
    grid-template-columns: 510px 1fr;
    padding: 85px 35px;
  }

  @include desktop-xl {
    column-gap: 121px;
    grid-template-columns: 740px 1fr;
    margin-right: auto;
    margin-left: auto;
    padding: 44px 0px;
  }
}

.col-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.articles h2 {
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 50px;
  line-height: 55px;
}

.articles-list {
  display: grid;
  column-gap: vc(33);
  justify-content: space-between;
  grid-template-columns: repeat(3, 384px);
  margin-bottom: 35px;
  row-gap: 40px;

  &__item {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    background-color: $color-white;
    cursor: pointer;

    .picture {
      width: 100%;
      height: 172px;
      transition: all 0.2s;
      object-fit: cover;

      @include laptop {
        height: 172px;
      }
    }

    .caption {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      height: 100%;
      padding: 20px 20px 25px 25px;

      @include devices {
        padding: 20px 25px 25px;
      }

      @include laptop {
        padding: 20px 20px 25px;
      }

      @include desktop-xl {
        padding: 20px 20px 25px 20px;
      }
    }

    .title {
      margin-bottom: 20px;
      font-size: 20px;
      line-height: 120%;
      color: $color-black;

      @include desktop-xl {
        font-size: 20px;
        line-height: 120%;
      }
    }

    .button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: auto;
      border: 2px solid $color-anti-flash-white;
      border-radius: 50px;
      padding: 16px 43px 16px 15px;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      text-align: center;
      color: $color-black;
      background-color: $color-anti-flash-white;

      &.button--arrow-right {
        &::after {
          content: "";
          position: absolute;
          right: 25px;
          top: 50%;
          width: 10px;
          height: 28px;
          background-image: url("../../../assets/images/icons/arrow-right.svg");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          transform: translateY(-50%);
          filter: invert(100%);
        }

        &:hover {
          background-color: transparent;

          &::after {
            background-image: url("../../../assets/images/icons/arrow-right-black.svg");
          }
        }
      }
    }

    &:hover {
      .picture {
        filter: brightness(70%);
      }

      .button.button--arrow-right {
        border: 2px solid $color-tangelo;
        color: $color-white;
        background-color: $color-tangelo;

        &::after {
          filter: none;
        }
      }
    }
  }

  @include devices {
    column-gap: 20px;
    grid-template-columns: 1fr;
    row-gap: 20px;
  }

  @include laptop {
    column-gap: 26px;
    grid-template-columns: repeat(3, 300px);
    row-gap: 26px;
  }

  @include desktop-xl {
    grid-template-columns: repeat(3, 435px);
    row-gap: 39px;
    margin-bottom: 45px;
  }
}

.articles-list--2 {
  @include desktop {
    grid-template-columns: repeat(2, 464px);
  }

  @media (min-width: 1376px) {
    grid-template-columns: repeat(2, 595px);
  }

  @include desktop-xl {
    grid-template-columns: repeat(2, 675px);

    .picture {
      height: 200px;
    }
  }
}
