@import "../../../scss/variables";
@import "../../../scss/mixins/breakpoint";

.tables {
  margin-bottom: 100px;
  padding: 0 20px;

  @include desktop1024 {
    padding: 0;
  }
}

.title {
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 50px;
  line-height: 55px;
  color: #141414;
}
