@import '../../scss/mixins/breakpoint';

.container{
  width: 100%;
  margin: 0 auto;
  //position: relative;

  //a[id='backButton'] {
  //  @include laptop {
  //    left: 20px;
  //  }
  //}

  @include laptop768 {
    padding: 0 20px;
  }

  @include desktop1024 {
    width: 954px;
    padding: 0;
  }

  @include desktop1376 {
    width: 1216px;
  }

  @include desktop1600 {
    position: relative;
    width: 1385px;
  }
}
