@import "src/scss/variables";
@import "../../scss/mixins/breakpoint";

.footer {
  width: 100%;
  border-top: 2px solid $color-timberwolf;
  padding: 36px 32px 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .infoBlock {
    //column-gap: 15px;
    position: relative;
    align-items: center;

    .infoLogo {
      margin-right: 15px;
      width: 30px;
      height: 30px;
      cursor: pointer;

      @include desktop1024 {
        &:hover {
          ~ .infoHoverBlock {
            z-index: 999;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    .infoBlockMob {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 162%;
      text-decoration: underline;

      &.activeInfoBlock {
        ~ .infoHoverBlock {
          opacity: 1;
          visibility: visible;
          z-index: 999;
        }
      }

      @include desktop1024 {
        display: none;
      }
    }

    .infoHoverBlockJoin {
      margin-bottom: 15px;
      font-size: 14px;

      @include desktop1024 {
        display: none;
      }
    }

    .infoHoverBlockAd {
      font-size: 14px;
      text-transform: uppercase;
      margin-top: 15px;

      @include desktop1024 {
        display: none;
      }
    }

    .advertising {
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      text-decoration: underline;
      color: rgba(20, 20, 20, 0.4);

      &.activeAdvertisingBlock {
        ~ .advertisingHover {
          opacity: 1;
          visibility: visible;
          z-index: 999;
        }
      }

      @include desktop1024 {
        &:hover {
          ~ .advertisingHover {
            z-index: 999;
            opacity: 1;
            visibility: visible;
          }
        }
      }
      @include mobileL{
        font-weight: 400;
      }
    }

    .advertisingHover {
      position: absolute;
      left: 40px;
      bottom: 0;
      border-radius: 8px;
      padding: 30px 25px 28px;
      width: 422px;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      color: #2B352E;
      background: $color-white;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.4s;
      filter: drop-shadow(0px 0px 4px rgba(124, 124, 124, 0.25));
      pointer-events: none;

      a {
        color: #2B352E;
        text-decoration: underline;
      }

      .forTable {
        @include desktop1024 {
          display: none;
        }
      }

      .advertisingHoverClose {
        display: none;

        @include laptop {
          position: absolute;
          right: 20px;
          top: 20px;
          display: flex;
          pointer-events: auto;
        }
      }

      @include laptop {
        left: 50%;
        bottom: 0;
        padding: 50px 25px 30px;
        width: 334px;
        font-size: 16px;
        line-height: 150%;
        filter: drop-shadow(0px 0px 4px rgba(124, 124, 124, 0.25));
        transform: translateX(-50%);
        pointer-events: auto;
      }

      @include desktop1600 {
        left: 55px;
        padding: 45px 35px;
        width: 515px;
        font-size: 16px;
        line-height: 150%;
      }
    }

    .infoHoverBlock {
      position: absolute;
      width: 432px;
      background: #FFF;
      border-radius: 8px;
      top: -186px;
      left: 0;
      z-index: 10;
      padding: 30px 25px 28px 25px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.4s;
      pointer-events: none;
      filter: drop-shadow(0px 0px 4px rgba(124, 124, 124, 0.25));

      .infoHoverBlockClose {
        display: none;

        @include laptop {
          position: absolute;
          right: 20px;
          top: 20px;
          display: flex;
          pointer-events: auto;
        }
      }

      h4 {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #2B352E;
        margin-bottom: 5px;

        @include laptop {
          margin-bottom: 25px;
          font-weight: 500;
          font-size: 16px;
          line-height: 125%;
        }
      }

      .infoHoverBlockDescription {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #2B352E;
        margin-bottom: 15px;

        @include laptop {
          margin-bottom: 0;
          font-size: 16px;
          line-height: 150%;
        }
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #2B352E;
      }

      @include laptop {
        width: 334px;
        left: 50%;
        bottom: 45px;
        padding: 35px 25px;
        top: auto;
        transform: translateX(-50%);
        text-align: left;
        pointer-events: auto;

        span {
          display: none;
        }
      }
    }

    @include desktop1024 {
      display: flex;
    }
  }

  .shareBlock {
    position: relative;

    .heartImage {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        width: 30px;
        height: 30px;
      }
    }

    .shareHoverBlock {
      position: absolute;
      right: -2px;
      top: -6px;
      display: none;
      justify-content: center;
      align-items: center;
      column-gap: 11px;
      background: $color-white;
      padding: 12px;
      border-radius: 200px;
      z-index: 5;
      filter: drop-shadow(0px 0px 4px rgba(124, 124, 124, 0.25));

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $color-black;
      }

      button {
        padding: 0;
        border: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $color-black;
        background-color: transparent;
        transition: 0.3s;
        cursor: pointer;

        @include desktop1024 {
          &:hover {
            color: $color-tangelo;
          }
        }
      }
    }

    &:hover .shareHoverBlock {
      display: flex;
    }
  }

  .shareMobile {
    position: relative;
    padding-bottom: 35px;
    margin-bottom: 30px;
    width: 100%;

    &::before {
      content: "";
      position: absolute;
      left: -35px;
      right: -35px;
      bottom: 0;
      height: 2px;
      background: #D7D7D7;
    }

    .shareMobileText {
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
    }

    .shareMobileButtons {
      display: grid;
      grid-column-gap: 50px;
      margin-top: 41px;
      justify-content: center;
      grid-template-columns: repeat(3, auto);

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: transparent;

        img {
          width: 40px;
        }
      }
    }

    @include desktop1024 {
      display: none;
    }
  }

  @include laptop {
    flex-direction: column;
    text-align: center;
  }
}

@include desktop1600 {
  .footer {
    width: 100%;
    padding: 35px 54px 45px;

    .infoBlock {
      //column-gap: 20px;

      .infoLogo {
        margin-right: 20px;
        width: 45px;
        height: 45px;
        margin-left: -4px;
      }

      .infoHoverBlock {
        width: 515px;
        top: -240px;
        left: -8px;
        padding: 45px 35px;

        h4 {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 15px;
        }

        .infoHoverBlockDescription {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 20px;
        }

        span {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    .shareBlock {
      .heartImage {
        svg {
          width: 45px;
          height: 45px;
        }
      }

      .shareHoverBlock {
        padding: 15px 25px;
        top: 0;

        a {
          font-size: 16px;
          line-height: 15px;
        }

        p {
          font-size: 16px;
          line-height: 15px;
        }
      }
    }
  }
}

@include desktop {
  .footer {
    width: 100%;
    padding: 30px 30px 45px 30px;
  }
}

@include laptop {
  .footer {
    .infoLogo,
    .shareBlock {
      display: none;
    }

    .infoBlock {
      .advertising {
        color: #141414;
      }
    }
  }
}
