// stylelint-disable scss/dollar-variable-empty-line-before

// COLORS
$color-black: #000;
$color-white: #fff;
$color-gray: #eee;
$color-white-smoke: #f7f7f5;
$color-anti-flash-white: #f4f4f3;
$color-onyx: #141414;
$color-tangelo: #fc4c02;
$color-eggshell: #e4f0d5;
$color-kelly-green: #67ae03;
$color-pale-lavender: #e3d3f7;
$color-blue-violet: #8737ec;
$color-violet: #6208bd;
$color-timberwolf: #d7d7d7;
$color-dark-gray: #abaaaa;
$color-dark-lava: #3a3a3a;
$color-gray-metro: #AFADAC;
$color-gray-button: #f4f4f3;
$color-medium-jungle-green: #292d3a;

:export{
  colorTangelo: $color-tangelo;
}

// Metro
$color-android-green: #a4c93f;

// FONTS
// stylelint-disable-next-line
$font-primary: -apple-system, blinkmacsystemfont, "Segoe UI", roboto, oxygen, ubuntu, cantarell, "Open Sans", "Helvetica Neue", sans-serif;
$font-graphik-rbc-lc: "GraphikRBCLC", sans-serif;

// EASING TRANSITIONS
$duration-normal: 0.4s;
$easing-back-out: cubic-bezier(0.25, 0.74, 0.22, 1.1); // http://cubic-bezier.com/#.25,.74,.22,1.1
$easing-back-in: cubic-bezier(0.47, -0.1, 0.5, -0.06); // http://cubic-bezier.com/#.47,-0.1,.5,-.06
$easing-out: ease-out;
$easing-in: ease-in;

// Settings
$desktop-xxl: 2400;
$desktop-xl: 1600;
$desktop: 1024;
$laptop: 1376;
$tablet: 768;
$mobile: 374;
$desktop-vh: 750;


@font-face {
  src: url("../fonts/GraphikRBCLC/GraphikRBCLC-Light.woff2") format("woff2"), url("../fonts/GraphikRBCLC/GraphikRBCLC-Light.woff") format("woff");
  font-family: "GraphikRBCLC";
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  src: url("../fonts/GraphikRBCLC/GraphikRBCLC-Regular.woff2") format("woff2"), url("../fonts/GraphikRBCLC/GraphikRBCLC-Regular.woff") format("woff");
  font-family: "GraphikRBCLC";
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  src: url("../fonts/GraphikRBCLC/GraphikRBCLC-RegularItalic.woff2") format("woff2"), url("../fonts/GraphikRBCLC/GraphikRBCLC-RegularItalic.woff") format("woff");
  font-family: "GraphikRBCLC";
  font-weight: 400;
  font-style: italic;
  font-display: swap; }

@font-face {
  src: url("../fonts/GraphikRBCLC/GraphikRBCLC-Medium.woff2") format("woff2"), url("../fonts/GraphikRBCLC/GraphikRBCLC-Medium.woff") format("woff");
  font-family: "GraphikRBCLC";
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  src: url("../fonts/GraphikRBCLC/GraphikRBCLC-SemiBold.woff2") format("woff2"), url("../fonts/GraphikRBCLC/GraphikRBCLC-SemiBold.woff") format("woff");
  font-family: "GraphikRBCLC";
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  src: url("../fonts/GraphikRBCLC/GraphikRBCLC-Bold.woff2") format("woff2"), url("../fonts/GraphikRBCLC/GraphikRBCLC-Bold.woff") format("woff");
  font-family: "GraphikRBCLC";
  font-weight: 700;
  font-style: normal;
  font-display: swap; }
