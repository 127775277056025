@import "../../../scss/variables";
@import "../../../scss/mixins/breakpoint";

@mixin filterBtn {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $color-onyx;
  background: transparent;
  border: 1px solid $color-timberwolf;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: $color-white;
    border-color: $color-white;
  }

  @include laptop {
    font-size: 16px;
  }

  @include mobileL {
    font-size: 16px;
    line-height: 16px;
  }

  @include mobileM {
    font-size: 14px;
  }
}

.buttonsBlock {
  display: flex;
  gap: 10px;

  .mapBtn {
    button {
      width: 146px;
      height: 48px;
      border-radius: 32px;
      padding: 4px 25px;
      font-size: 14px;
      line-height: 16px;
      white-space: nowrap;
      @include filterBtn;

      svg {
        margin-left: 15px;
        margin-right: -20px;
      }
    }

    @include laptop {
      order: -1;
    }
  }

  .roomsButtons {
    display: flex;
    column-gap: 10px;

    label {
      &:not(:first-child) {
        span {
          padding: 0;
          height: 48px;
        }
      }

      @include mobile431 {
        span {
          height: 60px;
          border-radius: 60px;
        }
        &:not(:first-child) {
          span {
            height: 60px;
            width: 60px !important;
          }
        }
      }

      @include desktop1024 {
        span {
          height: 48px;
          border-radius: 32px;
        }
        &:not(:first-child) {
          span {
            height: 48px;
            width: 48px !important;
          }
        }
      }
    }
  }
}

@include laptop {
  .buttonsBlock {
    display: grid;
    grid-row-gap: 20px;

    .mapBtn {
      button {
        width: 180px;
        height: 60px;
        border-radius: 60px;
        padding: 0;

        svg {
          width: 45px;
          height: 45px;
          margin-left: 15px;
          margin-right: -10px;
        }
      }
    }

    .roomsButtons {
      //gap: 14px;
      column-gap: 0;
      flex-wrap: wrap;

      label {
        + label {
          margin-left: 14px;
        }
      }

      .studio {
        width: 236px;
        height: 98px;
        border-radius: 60px;
      }

      .rooms {
        width: 98px;
        height: 98px;
      }
    }
  }
}

@include mobileL {
  .buttonsBlock {
    gap: 17px;

    .mapBtn {
      button {
        width: 168px;
        height: 54px;
        border-radius: 32px;
        padding: 18px 30px;

        svg {
          width: 45px;
          height: 45px;
          margin-left: 15px;
          margin-right: -25px;
        }
      }
    }

    .roomsButtons {
      //column-gap: 14px;

      label {
        + label {
          margin-left: 5px;
        }
      }

      .studio {
        width: 130px;
        height: 54px;
        border-radius: 32px;
      }

      .rooms {
        width: 54px;
        height: 54px;
      }
    }
  }
}

@include mobileM {
  .mapBtn {
    button {
      width: 148px;
      height: 48px;
      padding: 16px 27px;

      svg {
        width: 40px;
        height: 40px;
        margin-left: 12px;
        margin-right: -22px;
      }
    }
  }

  .roomsButtons {
    //column-gap: 7px;

    .studio {
      width: 115px;
      height: 48px;
    }

    .rooms {
      width: 48px;
      height: 48px;
    }
  }
}
