@import "../../../scss/variables";
@import "../../../scss/mixins/breakpoint";

.apartmentsWrapper{
  margin-top: 68px;
  .investmentsBlock2{
    display: flex;
    column-gap: 105px;
    background: $color-eggshell;
    border-radius: 8px;
      .investmentsBlockImg {
        width: 595px;
        height: 310px;

        img {
          width: 595px;
          height: 310px;
          border-radius: 8px;
          object-fit: cover;
        }
      }

      .investmentsDescription {
        display: flex;
        flex-direction: column;

        h3 {
          font-weight: 600;
          font-size: 50px;
          line-height: 55px;
          color: $color-onyx;
          margin-top: 15px;
        }

        p {
          font-weight: 400;
          font-size: 24px;
          letter-spacing: -0.02em;
          color: $color-black;
          margin: 15px 0 43px;
        }
      }
  }
  .investmentsBlock{
    display: flex;
    padding: 25px 0;
    column-gap: 105px;
    background: $color-eggshell;
    border-radius: 8px;
    .investmentsBlockImg{
      width: 595px;
      height: 310px;
      img{
        width: 595px;
        height: 310px;
        border-radius: 8px;
        object-fit: cover;
      }
    }
    .investmentsDescription{
      display: flex;
      flex-direction: column;
      h3{
        font-weight: 600;
        font-size: 50px;
        line-height: 55px;
        color: $color-onyx;
        margin-top: 15px;
      }
      p{
        font-weight: 400;
        font-size: 24px;
        letter-spacing: -0.02em;
        color: $color-black;
        margin: 15px 0 43px;
      }
    }
  }
}

@include desktop1600{
  .apartmentsWrapper{
    margin-top: 68px;
    .investmentsBlock{
      padding: 40px 0;
      .investmentsBlockImg{
        width: 680px;
        height: 330px;
        img{
          width: 680px;
          height: 330px;
        }
      }
      .investmentsDescription{
       p{
         font-size: 28px;
       }
      }
    }
  }
}

@include desktop{
  .apartmentsWrapper{
    margin-top: 68px;
    .investmentsBlock{
      column-gap: 70px;
      .investmentsBlockImg{
        width: 410px;
        height: 310px;
        img{
          width: 410px;
          height: 310px;
        }
      }
      .investmentsDescription{
        h3{
          font-size: 40px;
          line-height: 45px;
        }
        p{
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }
}

@include laptop{
  .apartmentsWrapper{
    margin-top: 100px;
    .investmentsBlock{
      flex-direction: column;
      padding: 70px 0;
      .investmentsBlockImg{
        order: 2;
        width: 100%;
        height: 420px;
        margin-top: 50px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .investmentsDescription{
        order: 1;
        h3{
          //font-size: 64px;
          //line-height: 64px;
          font-size: 42px;
          line-height: 100%;
        }
        p{
          //font-size: 46px;
          //line-height: 46px;
          margin-bottom: 30px;
          font-size: 22px;
          line-height: 30px;
        }
      }
    }
  }
}

@include tablet {
  .apartmentsWrapper {
    .investmentsBlock {
      .investmentsBlockImg {
        height: 400px;
      }
    }
  }
}

@media (max-width: 500px) {
  .apartmentsWrapper {
    .investmentsBlock {
      .investmentsBlockImg {
        height: 300px;
      }
    }
  }
}

@include mobileL{
  .apartmentsWrapper{
    margin-top: 90px;
    .investmentsBlock{
      padding: 80px 0;
      .investmentsBlockImg{
        height: 232px;
        margin-top: 56px;
      }
      .investmentsDescription{
        h3{
          font-size: 42px;
          line-height: 100%;
        }
        p{
          margin-bottom: 30px;
          font-size: 22px;
          line-height: 30px;
        }
      }
    }
  }
}

@include mobileM{
  .apartmentsWrapper{
    width: 100%;
    margin-top: 80px;
    .investmentsBlock{
      padding: 70px 0;
      .investmentsBlockImg{
        height: 205px;
        margin-top: 50px;
      }
      .investmentsDescription{
        h3{
          margin-top: 0;
          font-size: 36px;
          line-height: 108%;
        }
        p{
          margin-bottom: 30px;
          font-size: 22px;
          line-height: 30px;
        }
      }
    }
  }
}
