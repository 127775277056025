@mixin desktop2400 {
	@media(min-width: 2400px){
		@content
	}
}

@mixin desktop1600 {
	@media(min-width: 1600px){
		@content
	}
}

@mixin desktop1376 {
	@media (min-width: (1376px)) {
		@content;
	}
}

@mixin desktop1024 {
	@media (min-width: (1024px)) {
		@content;
	}
}

@mixin laptop768 {
	@media (min-width: (768px)) {
		@content;
	}
}

@mixin mobile431 {
	@media (min-width: (431px)) {
		@content;
	}
}

@mixin desktop1440 {
	@media(max-width: 1440px){
		@content
	}
}

@mixin desktop {
	@media(max-width: 1375px){
		@content
	}
}

@mixin laptop {
	@media(max-width: 1023px){
		@content
	}
}

@mixin tablet {
	@media(max-width: 767px){
		@content
	}
}

@mixin mobileL {
	@media(max-width: 430px){
		@content
	}
}

@mixin mobileM {
	@media(max-width: 375px){
		@content
	}
}

@mixin mobileS {
	@media(max-width: 320px){
		@content
	}
}

@mixin mobile {
	@media (max-width: (767px)) {
		@content;
	}
}

@mixin desktop-xxl {
	@media (min-width: (2400px)) {
		@content;
	}
}

@mixin desktop-1600 {
	@media (min-width: (1600px)) {
		@content;
	}
}

@mixin desktop-xl {
	@media (max-width: (1600px)) {
		@content;
	}
}

@mixin desktop-up {
	@media (min-width: (1024px)) {
		@content;
	}
}

@mixin devices {
	@media (max-width: (1023px)) {
		@content;
	}
}

@mixin laptop-2 {
	// change the values for the situation
	@media (min-width: ($desktop * 1px)) and (max-width: (($laptop - 1) * 1px)) {
		@content;
	}
}

@mixin tablet-2 {
	// change the values for the situation
	@media (min-width: ($tablet * 1px)) and (max-width: (($desktop - 1) * 1px)) {
		@content;
	}
}
