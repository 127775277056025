@import "../../../scss/variables";
@import "../../../scss/mixins/breakpoint";

@mixin radioBtn{
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $color-onyx;
  background: transparent;
  border: 1px solid $color-timberwolf;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover{
    background: $color-white;
    border-color: $color-white;
  }
  @include laptop{
    font-size: 16px;
  }
  @include mobileL{
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
  }
  @include mobileM{
    font-size: 14px;
  }
}

  .activeLabelStyle{
    @include radioBtn;
    height: 48px;
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 45px;
    border-radius: 32px;
    transition: 0.3s;

    @include mobileM {
      padding: 15px 30px;
    }

    @include desktop1024 {
      padding: 15px 30px;
    }
  }
 .inactiveLabelStyle{
   color: $color-black;
   border: 1px solid $color-timberwolf;
   background: $color-white;
   font-size: 14px;
   line-height: 16px;
   display: flex;
   height: 48px;
   align-items: center;
   font-weight: 400;
   cursor: pointer;
   justify-content: center;
   border-radius: 32px;
   padding: 15px 30px;
   transition: 0.3s;
   &:hover{
     background: $color-white;
     border-color: $color-white;
   }
   @include tablet{
     font-size: 16px;
   }
   @include mobileL{
     font-size: 16px;
     line-height: 16px;
     font-weight: 400;
   }
   @include mobileM{
     font-size: 14px;
   }
 }


.activeLabelStyleMobile{
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $color-onyx;
  background: transparent;
  border: 1px solid $color-timberwolf;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 32px;
  padding: 15px 30px;
  white-space: nowrap;

  @include mobileL{
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
  }
  @include mobileM{
    font-size: 14px;
  }

  @include mobile431{
    font-size: 16px;
  }

  @include desktop1024 {
    font-size: 14px;
  }
}
.inactiveLabelStyleMobile{
  color: $color-black;
  border: 1px solid $color-timberwolf;
  background: $color-white;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  height: 48px;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  justify-content: center;
  border-radius: 32px;
  padding: 15px 30px;
  &:hover{
    background: $color-white;
    border-color: $color-white;
  }
  @include tablet{
    font-size: 16px;
  }
  @include mobileL{
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
  }
  @include mobileM{
    font-size: 14px;
  }
}



