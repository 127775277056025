@import "src/scss/variables";
@import "../../../scss/mixins/breakpoint";

.button{
  background: $color-tangelo;
  border: 2px solid $color-tangelo;
  border-radius: 80px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: $color-white;
  padding: 16px 23px;
  transition: 0.5s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;

  &:hover{
    color: $color-black;
    background: transparent;
    border: 2px solid $color-tangelo;
  }
  image{
    flex-shrink: 0;
    width: 5.66px;
    height: 5.66px;
    &hover{
      background: black;
    }
  }

  svg {
    flex-shrink: 0;
    stroke-width: 20px;

    @include desktop1600 {
      margin-top: 1px;
    }
  }

  @include desktop1600 {
    padding: 13px 26px;
    font-size: 16px;
    line-height: 20px;
  }
}
