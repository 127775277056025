@import "../../../scss/variables";
@import "../../../scss/mixins/breakpoint";

.button {
  position: absolute;
  left: 20px;
  top: 80px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #D7D7D7;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  color: #141414;

  svg {
    margin-left: 1px;
    width: 10px;
    height: 10px;
    fill: none;

    @include desktop1024 {
      width: 12px;
      height: 12px;
    }
  }

  //@include laptop768 {
  //  left: 0;
  //  top: -39px;
  //}

  @include desktop1024 {
    left: 35px;
    top: 86px;

    &:hover {
      border-color: #FC4C02;
      color: #FC4C02;
    }
  }

  //@include desktop1376 {
  //  top: -41px;
  //  left: -45px;
  //}

  @include desktop1600 {
    top: -38px;
    left: -54px;
    width: 38px;
    height: 38px;
  }

  @include desktop2400 {
    left: -106px;
  }
}
