@import "src/scss/variables";
@import "../../../scss/mixins/breakpoint";

.feedbackWrapper{
  margin: 20px 0 36px;
  //display: flex;
  //justify-content: flex-start;

  .formBlock{
    width: 100%;

    @include laptop768 {
      //width: 80%;
    }

    @media (min-width: 954px) {
      width: 100%;
    }

    @include desktop1024 {
      width: 110%;
      margin: 0 -52px;
    }

    @include desktop1376 {
      width: 100%;
      margin: 0 0 0 -60px;
    }

    @include desktop1600 {
      margin: 0 0 0 -130px;
    }
  }

  iframe {
    height: 670px;

    @include desktop1024 {
      height: 470px;
    }
  }

  @include desktop1600 {
    margin: 20px 0 46px;
  }
}
