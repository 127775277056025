@import "../../scss/variables";
@import "../../scss/mixins/breakpoint";

.aboutProjectWrapper {
  position: relative;
  display: grid;
  padding: 0 20px;
  grid-row-gap: 40px;

  //@include mobileM {
  //  margin-bottom: 320px;
  //}
  //
  //@include mobileL {
  //  margin-bottom: 350px;
  //}

  @include laptop768 {
    width: 100%;
    padding: 0;
  }

  @include desktop1024 {
    display: flex;
    justify-content: space-between;
  }

  @include desktop2400 {
    margin-top: 70px;
  }

  @include desktop1600 {
    //height: 495px;
  }


}

.leftSide {
  width: 565px;
  display: flex;
  flex-direction: column;
  text-align: left;
  will-change: transform, opacity;

  h1 {
    font-weight: 600;
    font-size: 65px;
    line-height: 70px;
    color: $color-onyx;
    margin-bottom: 5px;
  }

  p {
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: $color-black;
    margin: 15px 0 114px;
    width: 549px;
  }
  .mainPageDescription{
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: $color-black;
    margin: 15px 0 114px;
    width: 60%;
  }

  @include desktop1600 {
    width: 686px;

    h1 {
      font-size: 75px;
      line-height: 75px;
    }

    p {
      font-size: 28px;
      margin-top: 25px;
    }
  }

  @include desktop {
    width: 40%;

    h1 {
      font-size: 44px;
      line-height: 48px;
    }

    p {
      width: 100%;
      font-size: 19px;
      line-height: 29px;
      margin-bottom: 0;
    }
    .mainPageDescription{
      font-size: 19px;
      line-height: 29px;
      margin-bottom: 0;
    }
  }

  @include laptop {
    width: 100%;

    h1 {
      font-size: 54px;
      line-height: 100%;
    }

    P {
      font-size: 20px;
      line-height: 30px;
    }
    .mainPageDescription{
      width: 100%;
      font-size: 20px;
      line-height: 30px;
    }
  }

  @include mobileL {
    h1 {
      font-size: 38px;
    }

    P {
      font-size: 24px;
      width: auto;
    }
    .mainPageDescription{
      font-size: 24px;
    }
  }

  @include mobileM {
    h1 {
      font-size: 37px;
      line-height: 111%;
    }

    P {
      margin-top: 11px;
      font-size: 22px;
      line-height: 136%;
    }
    .mainPageDescription{
      margin-top: 11px;
      font-size: 22px;
      line-height: 136%;
    }
  }

  @include mobileS {
    h1 {
      font-size: 32px;
      line-height: 111%;
    }

    P {
      font-size: 20px;
    }
    .mainPageDescription{
      font-size: 20px;
    }
  }
}

.rightSide {
  flex-shrink: 0;
  width: 644px;
  height: 495px;
  overflow: hidden;
  border-radius: 8px;
  will-change: transform, opacity;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include desktop1600 {
    right: 0;
    margin-right: 0;
  }

  @include desktop {
    margin-right: 0;
    width: 536px;
  }

  @include laptop {
    right: 0;
    width: 100%;
    height: 450px;
  }

  @include tablet {
    height: 400px;
  }

  @media (max-width: 540px) {
    height: 320px;
  }

  @include mobileL {
    height: 232px;
  }

  @include mobileM {
    height: 205px;
  }
}
