@import "src/scss/variables";
@import "../../../scss/mixins/breakpoint";


@mixin cardDescription{
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.04em;
  color: $color-black;
  transition: 0.3s;
}

.carouselWrapper{
  margin-top: 30px;

  .carsWrapperBg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: 50% 15% no-repeat;
    background-size: cover;
  }

  .firstCard,
  .secondCard,
  .thirdCard {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 445px;
    padding: 30px 20px;
    cursor: pointer;
    text-align: left;
    border-radius: 4px;
    overflow: hidden;
    transition: 0.3s;

    p{
      @include cardDescription
    }
  }

  .secondCard{
    p {
      color: #323232;
    }

    //span {
    //  color: #90C500;
    //}

    .carsWrapperNumb {
      border-radius: 250px;
      padding: 2px 15px;
      color: $color-white;
      background: #FC4C02;
    }

    .carsWrapperDate {
      display: block;
      margin-top: 5px;
      font-size: 20px;
      line-height: 20px;
      color: #8C8279;
    }

    .carsWrapperButton {
      margin-top: auto;
      border-radius: 74px;
      padding: 6px 16px;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.01em;
      color: $color-white;
      background: #8C8279;
    }
  }

  :global(.carousel.carousel-slider) {
    overflow: visible;
  }

  :global(.carousel .slider-wrapper) {
    overflow: visible;
  }

  :global(.carousel .slider) {
    padding-right: 10px;
    margin-right: -10px;
    width: auto;
  }

  :global(.carousel .slide + .slide) {
    margin-left: 10px;
  }

  :global(.carousel .control-arrow) {
    top: auto;
    bottom: -66px;
    border: 1px solid #D7D7D7;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    opacity: 1;
    background: none;

    &::before {
      border: none;
      background-image: url("../../../assets/images/icons/arrow-right-black-2.svg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 7px;
      height: 10px;
      margin-left: 1px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  :global(.carousel .control-arrow.control-prev) {
    left: 115px;

    &::before {
      margin-left: -1px;
      transform: translate3d(-50%, -50%, 0) rotate(180deg);
    }
  }

  :global(.carousel .control-arrow.control-next) {
    right: 115px;
  }

  @include laptop768 {
    display: none;
  }
}
