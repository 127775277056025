@import "src/scss/variables";
@import "../../../scss/mixins/breakpoint";
@mixin description{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $color-onyx;
  @include desktop1600{
    font-size: 20px;
  }
}

.investmentTable{
  padding: 0 24px 0 24px;
  margin: 35px 0;
  background: $color-white;
  border-radius: 8px;
  overflow-x: auto;

  > a {
    &:first-child {
      .tableRow{
        border-top: none;
      }
    }
  }

  &::-webkit-scrollbar {
    height: 2px;
    background-color: $color-timberwolf;
    width: 100px;
    position: fixed;
    left: 100px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-tangelo;
    border-radius: 100px;
  }
  .tableRow1{
    border-top: 1px solid $color-timberwolf;
    &:first-child {
      border-top: none;
    }
  }
  .tableRow{
    display: flex;
    justify-content: space-between;
    padding: 26px 0;
    width: 100%;
    height: 100px;

    &:first-child {
      //border-top: none;
    }
    &:hover{
      .fourthCol{
        .arrowLink{
          color: $color-tangelo;
        }
      }
    }

    .location{
      width: 28%;
      .locationTitle{
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: $color-onyx;
      }
      .subway{
        display: flex;
        align-items: center;
        margin-top: 2px;

        div{
          flex-shrink: 0;
          border-radius: 50%;
          width: 8px;
          height: 8px;
        }
        p{
          margin: 0 20px 0 8px;
          font-size: 13px;
          font-weight: 400;
          line-height: 24px;
          color: $color-onyx;

          @include desktop1024 {
            font-size: 14px;
          }

          @include desktop1600{
            font-size: 18px;
          }
        }
        span{
          margin-left: 8px;
          font-size: 13px;
          font-weight: 400;
          line-height: 24px;
          color: $color-onyx;

          @include desktop1024 {
            font-size: 14px;
          }

          @include desktop1600{
            font-size: 18px;
          }
        }

        .deliveryTime {
          display: flex;
          align-items: center;
          border-radius: 0;
          margin-left: 8px;
          width: auto;
          height: auto;
        }
      }
    }
    .secondCol{
      width: 25%;
      display: flex;
      flex-direction: column;
      p{
        @include description;

        + p {
          margin-top: 2px;
        }
      }
    }
    .thirdCol{
      width: 30%;
      display: flex;
      column-gap: 28px;
      .paymentBlock{
        p{
          @include description;

          + p {
            margin-top: 2px;
          }
        }
      }
      .amountPayable{
        text-align: left;
        display: flex;
        flex-direction: column;
        column-gap: 10px;
        p{
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: $color-onyx;

          + p {
            margin-top: 2px;
          }
        }
      }
    }
    .fourthCol{
      width: 17%;
      display: flex;
      align-items: center;
      column-gap: 20px;
      justify-content: space-between;
      p{
        @include description;
      }
      .arrowLink{
        color: #afadac;
        font-size: 28px;
        transition: 0.3s;
      }
    }
  }
}
.showAllBtn{
  background: $color-white;
  border-radius: 80px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  padding: 15px 23px;
  color: $color-black;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  transition: 0.3s;
  cursor: pointer;
  &:hover{
    background: $color-tangelo;
    color: $color-white;
  }
  .scrollBar{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: red;
    transform-origin: 0;
  }

  @include desktop1600 {
    padding: 20px 25px;
    font-size: 16px;
    line-height: 20px;
  }

  @include desktop {
    padding: 17px 24px;
  }
}

@include desktop1600{
  .investmentTable{
    margin: 40px 0 45px 0;
    .tableRow1{
      width: 1337px;
    }
    .tableRow{
      padding: 26px 0 27px 0;
      height: 113px;
      .location{
        width: 25%;
        .locationTitle{
          font-weight: 500;
          font-size: 24px;
          line-height: 24px;
          color: $color-onyx;
        }
        .subway{
          margin-top: 8px;
        }
      }

      .secondCol {
        width: 26%;
        p{
          + p {
            margin-top: 8px;
          }
        }
      }

      .thirdCol{
        width: 31.5%;
        column-gap: 40px;
        .paymentBlock{
          p{
            + p {
              margin-top: 8px;
            }
          }
        }
        .amountPayable{
          column-gap: 10px;
          p{
            font-size: 20px;
            line-height: 23px;
            + p {
              margin-top: 8px;
            }
          }
        }
      }

      .fourthCol {
        column-gap: 53px;
      }
    }
  }
}
@include desktop{
  .investmentTable{
    margin: 35px 0 40px 0;
    .tableRow1 {
      width: 1216px;
    }
    .tableRow{
      padding: 24px 0 24px 0;
      .location {
        width: 26%;
        .subway {
          margin-top: 2px;
        }
      }
      .secondCol{
        width: 26%;
        p{
          + p {
            margin-top: 2px;
          }
        }
      }
      .thirdCol {
        width: 30%;
        .paymentBlock{
          p{
            + p {
              margin-top: 2px;
            }
          }
        }
        .amountPayable{
          p {
            + p {
              margin-top: 2px;
            }
          }
        }
      }
      .fourthCol{
        width: 18%;
      }
    }
  }
}
@include laptop{
  .investmentTable{
    margin: 30px 0 35px 0;
    padding: 0 24px 0 24px;
    .tableRow1 {
      width: 1160px;
    }
    .tableRow{
      .location{
        width: 23.5%;
        padding-right: 20px;
      }
      .secondCol{
        width: 23.5%;
        p{
          + p {
            margin-top: 2px;
          }
        }
      }
      .thirdCol{
        width: 32%;
        .paymentBlock{
          p{
            + p {
              margin-top: 2px;
            }
          }
        }
        .amountPayable{
          p{
            + p {
              margin-top: 2px;
            }
          }
        }
      }
      .fourthCol{
        width: 17%;
      }
    }
  }
}
