@import "variables";
@import "mixins/breakpoint";

.readAlso {
  margin: 0 80px 100px;
  max-width: 1384px;

  h2 {
    margin-bottom: 30px;
    font-weight: 600;

    @include devices {
      font-size: 36px;
      line-height: 108%;
    }

    @include laptop-2 {
      font-size: 40px;
      line-height: 112%;
    }

    @include desktop1600 {
      font-size: 50px;
      line-height: 110%;
    }
  }

  .readAlsoList {
    display: grid;
    column-gap: 33px;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 35px;
    row-gap: 40px;

    .readAlsoListItem {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      height: 100%;
      width: 100%;
      background-color: $color-white;
      cursor: pointer;

      .readAlsoPicture {
        width: 100%;
        height: 172px;
        transition: all 0.2s;
        object-fit: cover;
        flex-shrink: 0;

        @include laptop-2 {
          height: 172px;
        }
      }

      .readAlsoCaption {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        padding: 20px 20px 25px 25px;

        @include mobile {
          padding: 20px 25px 25px;
        }

        @include laptop768 {
          padding: 20px;
        }

        @include laptop-2 {
          padding: 20px 20px 25px;
        }

        @include desktop1600 {
          padding: 25px 25px 30px 25px
        }
      }

      .readAlsoTitle {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 120%;
        color: $color-black;
        letter-spacing: -0.02em;

        @include laptop768 {
          font-size: 18px;
        }

        @include desktop1024 {
          font-size: 20px;
          line-height: 120%;
        }
      }

      &:hover {
        .readAlsoPicture {
          filter: brightness(70%);
        }

        .readAlsoButton {
          border: 2px solid $color-tangelo;
          color: $color-white;
          background-color: $color-tangelo;

          &::after {
            filter: none;
          }
        }
      }
    }

    .readAlsoButton {
      margin-top: auto;
      border: 2px solid $color-anti-flash-white;
      padding: 15px 43px 15px 25px;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: $color-black;
      background-color: $color-anti-flash-white;
      align-items: center;
      border-radius: 80px;
      cursor: pointer;
      display: inline-flex;
      outline: none;
      position: relative;
      text-align: center;
      transition: $duration-normal;

      &::after {
        right: 23px;
        background-image: url("../assets/images/icons/arrow-right.svg");
        filter: invert(100%);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
        height: 28px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        transition: $duration-normal;
      }
    }

    @include mobile {
      column-gap: 20px;
      grid-template-columns: 1fr;
      row-gap: 20px;
    }

    @include laptop768 {
      column-gap: 20px;
    }

    @include laptop-2 {
      column-gap: 26px;
      row-gap: 26px;
    }

    @include desktop1600 {
      column-gap: 33px;
      row-gap: 39px;
      margin-bottom: 45px;
    }
  }

  .readAlsoList2 {
    grid-template-columns: 1fr 1fr;

    @include mobile {
      grid-template-columns: 1fr;
    }

    @include desktop1600 {
      .readAlsoListItem {
        .readAlsoPicture {
          height: 200px;
        }
      }
    }
  }

  @include devices {
    margin: 20px 20px 80px;
  }

  @include laptop-2 {
    margin-right: 35px;
    margin-left: 35px;
  }

  @include desktop1600 {
    margin: 0 auto 100px;
  }
}
