@import "scss/fonts";
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'GraphikRBCLC', sans-serif;
  font-style: normal;
  scroll-behavior: smooth;
}

:root {
  --width-base: 1376;
  --width-current: 100vw;
  --width-multiplier: var(--width-current) / var(--width-base);
  --overflow: hidden;
}

div[id='root'] {
  overflow: hidden;
}

.ovferlow--hidden {
  overflow: hidden;
}

body {
  background: #f7f7f5;
}

a {
  text-decoration: none;
}

iframe {
  border: none;
  width: 100%;
}

.is-hidden {
  display: none !important;
}

@media (min-width: 767px) {
  .for-mobile {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .for-desktop768 {
    display: none !important;
  }
}

@media (max-width: 1023px) {
  .for-desktop {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .for-devices {
    display: none !important;
  }
}
