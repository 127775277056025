@import "../../../scss/variables";
@import '../../../scss/mixins/breakpoint';

.apartmentsWrapper{
  margin-top: 100px;
  .investmentsBlock{
    display: flex;
    padding: 25px 40px;
    column-gap: 105px;
    background: #E3D3F7;
    border-radius: 8px;
    .investmentsImg{
      flex-shrink: 0;
      width: 595px;
      height: 310px;
      border-radius: 8px;
      object-fit: cover;
    }
    .investmentsDescription{
      display: flex;
      flex-direction: column;
      h3{
        font-weight: 600;
        font-size: 50px;
        line-height: 55px;
        color: $color-onyx;
        margin-top: 15px;
      }
      p{
        font-weight: 400;
        font-size: 24px;
        color: $color-black;
        margin: 15px 0 37px;
        line-height: 125%;
      }
    }
  }
}

@include desktop1600{
  .apartmentsWrapper{
    .investmentsBlock{
      padding: 41px 54px 39px 55px;
      column-gap: 99px;
      .investmentsImg{
        width: 680px;
        height: 330px;
      }
      .investmentsDescription{
        p{
          font-size: 28px;
          width: 440px;
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@include desktop{
  .apartmentsWrapper{
    .investmentsBlock{
      column-gap: 70px;
      .investmentsImg{
        width: 410px;
        height: 310px;
      }
      .investmentsDescription{
        h3{
          font-size: 40px;
          line-height: 45px;
          //width: 370px;
        }
        p{
          font-size: 20px;
          line-height: 30px;
          //width: 370px;
        }
      }
    }
  }
}

@include laptop{
  .apartmentsWrapper{
    margin-top: 100px;
    .investmentsBlock{
      padding: 70px 20px;
      flex-direction: column;
      .investmentsImg{
        order: 2;
        width: 100%;
        height: 420px;
        margin-top: 50px;
      }
      .investmentsDescription{
        order: 1;
        h3{
          font-size: 42px;
          line-height: 100%;
        }
        p{
          margin-bottom: 30px;
          font-size: 22px;
          line-height: 30px;
        }
      }
    }
  }
}

@include tablet {
  .apartmentsWrapper {
    .investmentsBlock {
      .investmentsImg {
        height: 400px;
      }
    }
  }
}

@media (max-width: 500px) {
  .apartmentsWrapper {
    .investmentsBlock {
      .investmentsImg {
        height: 300px;
      }
    }
  }
}

@include mobileL{
  .apartmentsWrapper{
    margin-top: 90px;
    .investmentsBlock{
      padding: 80px 22px;
      .investmentsImg{
        height: 240px;
        margin-top: 56px;
      }
      .investmentsDescription{
        h3{
          font-size: 36px;
          line-height: 100%;
        }
        p{
          margin-bottom: 30px;
          font-size: 22px;
          line-height: 30px;
        }
      }
    }
  }
}

@include mobileM{
  .apartmentsWrapper{
    margin-top: 80px;
    .investmentsBlock{
      padding: 70px 20px;
      .investmentsImg{
        height: 212px;
        margin-top: 50px;
      }
      .investmentsDescription{
        h3{
          margin-top: 0;
          font-size: 36px;
          line-height: 108%;
        }
        p{
          font-size: 22px;
          line-height: 136%;
          margin-bottom: 30px;
        }
      }
    }
  }
}
