@import "src/scss/variables";
@import "../../scss/mixins/breakpoint";

.headerWrapper {
  display: flex;
  justify-content: space-between;
  padding: 33px 34px;
  margin-bottom: 26px;
  position: relative;

  .rbkLogo {
    display: flex;

    a {
      display: flex;
      transition: $duration-normal;

      @include desktop1024 {
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  .rightSideBlock {
    display: flex;
    column-gap: 16px;
    align-items: center;

    .headerDescription {
      font-weight: 400;
      // 18->16
      font-size: 16px;
      line-height: 24px;
      color: $color-onyx;
    }

    .pikLogo {
      display: flex;

      a {
        display: flex;
        transition: $duration-normal;

        @include desktop1024 {
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

@include desktop2400 {
  .headerWrapper {
    padding: 50px 80px 0 80px;
  }

  .rbkLogo {
    svg {
      width: 105px;
      height: 30px;
    }
  }

  .rightSideBlock {
    column-gap: 20px;

    .pikLogo {
      svg {
        width: 91.25px;
        height: 28.08px;
      }
    }
  }
}

@include desktop1600 {
  .headerWrapper {
    padding: 50px 54px 0 54px;
    margin-bottom: 74px;

    .rbkLogo {
      svg {
        width: 94px;
        height: 27px;
      }
    }

    .rightSideBlock {
      .pikLogo {
        svg {
          width: 87px;
          height: 27px;
        }
      }
    }
  }
}

@include desktop {
  .headerWrapper {
    margin-bottom: 42px;
    padding: 32px 35px;

    .rbkLogo {
      svg {
        width: 83px;
        height: 24px;
      }
    }

    .rightSideBlock {
      .pikLogo {
        svg {
          width: 78px;
          height: 24px;
        }
      }
    }

    .headerDescription {
      display: none;
    }
  }
}

//@include desktop{
//  .headerWrapper{
//    padding: 60px 40px;
//    .rbkLogo{
//      svg {
//        width: 134px;
//        height: 42px;
//      }
//    }
//    .rightSideBlock{
//      .pikLogo{
//        svg {
//          width: 126px;
//          height: 40px;
//        }
//      }
//      .headerDescription{
//        display: none;
//      }
//    }
//  }
//}

@include laptop {
  .headerWrapper {
    padding: 34px 22px;

    .rbkLogo {
      svg {
        width: 75px;
        height: 24px;
      }
    }

    .rightSideBlock {
      .pikLogo {
        svg {
          width: 70px;
          height: 22px;
        }
      }
    }
  }
}

@include mobileM {
  .headerWrapper {
    padding: 30px 20px;

    .rbkLogo {
      svg {
        width: 66px;
        height: 20px;
      }
    }

    .rightSideBlock {
      .pikLogo {
        svg {
          width: 62px;
          height: 19px;
        }
      }
    }
  }
}

.burgerBtn {
  display: none;
  position: relative;
  width: 44px;
  height: 20px;
  transition: all 0.3s;

  &.active {
    left: 0;
    width: 36px;

    span {
      &:first-child {
        top: 50%;
        transform: rotate(45deg);
      }

      &:last-child {
        bottom: 50%;
        transform: rotate(-45deg);
      }
    }
  }
}

@include laptop {
  .burgerBtn {
    display: block;
  }
}

.burgerBtn span {
  position: absolute;
  border-bottom: 1px solid $color-onyx;
  width: 100%;
  transition: all 0.3s;

  &:first-child {
    top: 20%;
  }

  &:last-child {
    bottom: 20%;
  }
}

.menu__wrapper {
  position: fixed;
  left: 0;
  top: 80px;
  bottom: unset;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  background-color: $color-white-smoke;
  transition: max-height 0.8s;

  .menu {
    display: inline-flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    margin-bottom: 20px;
    width: 100%;
    min-height: 405px;
    font-size: 14px;
    line-height: 114%;
    text-align: center;

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 25px;
      font-weight: 400;
      font-size: 24px;
      line-height: 125%;
      white-space: nowrap;
      letter-spacing: -0.02em;
      color: $color-black;
      transition: color 0.15s;
      cursor: pointer;

      &--active {
        color: $color-tangelo;
      }
    }
  }

  .phone {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border: 1px solid $color-timberwolf;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    background-color: rgba(215, 215, 215, 0.4);
    cursor: pointer;

    svg {
      width: 25px;
      height: 25px;
    }
  }

  &.active {
    overflow-y: auto;
    padding-bottom: 80px;
    height: 100vh;
    max-height: 5000px;
    opacity: 1;
    visibility: visible;
    transition: max-height 0.8s;
  }

  &.is-bottom {
    bottom: 150px;
  }
}
