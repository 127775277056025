@import 'src/scss/variables';
@import '../../scss/mixins/breakpoint';

.navWrapperAbsolute {
  position: absolute;
}
.navWrapper1 {
  bottom: 150px !important;
}
.navWrapper {
  transition: 0.3s;
  display: flex;
  column-gap: 50px;
  position: fixed;
  z-index: 5;
  bottom: 40px;
  left: 52.6%;
  transform: translateX(-50%);

  .linksWrapper {
    display: flex;
    background: #F1F1F0;
    border: 1px solid $color-timberwolf;
    border-radius: 28px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .link {
      white-space: nowrap;
      padding: 16px 24px;
      text-decoration: none;
      font-weight: 400;
      font-size: 14px;
      color: $color-black;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        color: $color-tangelo;
      }
    }
  }

  .logoBlock {
    width: 57px;
    height: 57px;
    border-radius: 50%;
    background: #F1F1F0;
    border: 1px solid $color-timberwolf;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

@include desktop1600 {
  .navWrapper {
    column-gap: 40px;

    .linksWrapper {
      //width: 1030px;
      //padding: 20px 28px;

      a {
        padding: 16px 24px;
        font-size: 16px;
      }
    }

    .logoBlock {
      width: 56px;
      height: 56px;

      svg {
        width: 26px;
        height: 27px;
      }
    }
  }
}

@include desktop {
  .navWrapper {
    left: 50%;
    column-gap: 10px;

    .linksWrapper {
      //width: 843px;
      //padding: 20px 19px;
      //column-gap: 30px;

      .link {
        padding: 16px 15px;
        font-size: 13px;
      }
    }
  }

  .logoBlock {
    width: 56px;
    height: 56px;
  }
}

@include laptop {
  .navWrapper {
    display: none;
  }
}
